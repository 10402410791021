const TYPES = {
  CLICK_NOTIFICATION_CARD: 'ca.craver.web.click_notification_card.v1',
  CLICK_NOTIFICATION_CTA: 'ca.craver.web.click_notification_cta.v1',
  CLICK_NAVIGATE_TO_NOTIFICATIONS_PAGE: 'ca.craver.web.click_navigate_to_notifications_page.v1',
  CLICK_NOTIFICATION_SELECTABLE_CHIP: 'ca.craver.web.click_notification_selectable_chip.v1',
};

const PAGES = {
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_ACTION_SHEET: 'notification-action-sheet',
};

const SLOTS = {
  HEADER_ROW: 'header-row',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  DRAWER_SLOT: 'drawer-slot',
};

const PLACEMENTS = {
  HEADER_NAV_BAR: 'header-nav-bar',
  NOTIFICATION_CARD_LIST: 'notification-card-list',
  NOTIFICATION_ACTION_SHEET_CTA: 'notification-action-sheet-cta',
  DRAWER: 'drawer',
  NEW_READ_LIST_TOGGLES: 'new-read-list-toggles',
};

const CONTROLS = {
  NOTIFICATION_CARD: 'notification-card',
  NOTIFICATION_CTA_BUTTON: 'notification-cta-button',
  BELL_ICON: 'bell-icon',
  NOTIFICATIONS_BUTTON: 'notifications-button',
  NOTIFICATION_SELECTABLE_CHIP: 'notification-selectable-chip',
};

export {
  TYPES,
  PAGES,
  SLOTS,
  PLACEMENTS,
  CONTROLS,
};
