import React from 'react';
import { bool, func, string } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import '../../../css/notificationsPageV2/components/NotificationActionSheetHeader.scss';

import getResponsiveClassname from '../../../utils/responsiveClassnames';

const NotificationActionSheetHeader = ({
  isMobile,
  isTablet,
  title,
  setIsOpen,
  imageURL,
}) => {
  if (imageURL) {
    return (
      <section className="notificationActionSheetHeader-withImage-headerContainer">
        <section className="notificationActionSheetHeader-imageContainer">
          <img src={imageURL} alt="" className="notificationActionSheetHeader-image" />
          <section className="notificationActionSheetHeader-withImage-closeButtonContainer">
            <CloseIcon
              className="notificationActionSheetHeader-withImage-closeIcon"
              onClick={() => setIsOpen(false)}
            />
          </section>
        </section>
        <section className={getResponsiveClassname('notificationActionSheetHeader-withImage-titleTextContainer', isMobile, isTablet)}>
          <p className={getResponsiveClassname('notificationActionSheetHeader-titleText', isMobile, isTablet)}>
            {title}
          </p>
        </section>
      </section>
    );
  }

  return (
    <section className="notificationActionSheetHeader-titleHeaderRow">
      <section className={getResponsiveClassname('notificationActionSheetHeader-titleTextContainer', isMobile, isTablet)}>
        <p className={getResponsiveClassname('notificationActionSheetHeader-titleText', isMobile, isTablet)}>
          {title}
        </p>
      </section>
      <section className={getResponsiveClassname('notificationActionSheetHeader-closeButtonContainer', isMobile, isTablet)}>
        <CloseIcon
          className="notificationActionSheetHeader-closeIcon"
          onClick={() => setIsOpen(false)}
        />
      </section>
    </section>
  );
};

NotificationActionSheetHeader.propTypes = {
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
  title: string.isRequired,
  setIsOpen: func.isRequired,
  imageURL: string,
};

NotificationActionSheetHeader.defaultProps = {
  imageURL: '',
};

export default NotificationActionSheetHeader;
