import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  arrayOf,
  any,
  func,
  oneOf,
  objectOf,
} from 'prop-types';
import {
  List,
  Typography,
  withWidth,
} from '@material-ui/core';
import ListItemCard from '../shared/ListItemCard';
import SelectableChipWithCount from '../shared/SelectableChipWithCount';
import SelectableChip from '../shared/SelectableChip';
import NotificationListSkeletonLoader from './components/NotificationListSkeletonLoader';
import * as Routes from '../../services/routes/Routes.json';
import {
  getLoading,
  getNewNotifications,
  getReadNotifications,
  getUserToken,
} from '../../selectors';
import { checkForNotifications } from '../../services/helpers/CustomHooks';
import '../../css/notificationsPageV2/NotificationsPageV2.scss';
import DateHelper from '../../services/helpers/DateHelper';
import { CHIP } from '../../services/constants/Constants';
import * as Functions from '../../services/functions/Functions';
import CloseButtonComponent from '../core/components/CloseButton';
import NotificationActionSheet from './components/NotificationActionSheet';
import useIsTablet from '../../shared/hooks/useIsTablet';
import * as Actions from '../../actions/Actions';
import EmptyNotifications from './components/EmptyNotifications';
import getThemedResponsiveClassname from '../../utils/themedResponsiveClassnames';
import getResponsiveClassname from '../../utils/responsiveClassnames';
import getThemedClassname from '../../utils/themedClassnames';
import NotificationViewSkeletonLoader from './components/NotificationViewSkeletonLoader';
import NotificationCard from './components/NotificationCard';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import ClickNotificationCardEvent from '../../events/Notifications/ClickNotificationCardEvent';
import getClickEventClientXY from '../../events/utils/getClickEventClientXY';
import ClickNotificationSelectableChipEvent from '../../events/Notifications/ClickNotificationSelectableChipEvent';

const getSelectedChipTitle = (selectedChip, translation) => {
  switch (selectedChip) {
    case CHIP.new:
      return translation('NotificationsPageV2.new');
    case CHIP.read:
      return translation('NotificationsPageV2.read');
    default:
      return '';
  }
};

const NotificationsComponent = (props) => {
  const {
    history,
    user,
    translation,
    upliftHistory,
    width,
    newNotifications,
    readNotifications,
    updateNotification,
    setError,
  } = props;
  const loading = useSelector(getLoading);
  const [selectedChip, setSelectedChip] = useState(CHIP.new);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [isSingleViewOpen, setIsSingleViewOpen] = useState(false);
  const isMobile = Functions.isMobileV2(width);
  const isTablet = useIsTablet();
  const notifications = selectedChip === CHIP.new ? newNotifications : readNotifications;
  const isResponsiveDesign = isMobile || isTablet;
  const cloudEventSubjectParams = useCloudEventsBaseParams();
  const userToken = useSelector(getUserToken);

  useEffect(() => {
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);
  }, []);

  useEffect(() => {
    setSelectedNotification(null);
    setSelectedNotificationId(null);
  }, [selectedChip]);

  if (!user) {
    history.push(Routes.path.menuPage);
    return null;
  }

  checkForNotifications(user);

  const handleNotificationClick = async (notification, clickEvent) => {
    setSelectedNotification(notification);
    setSelectedNotificationId(notification.id);
    setIsSingleViewOpen(true);
    const positionParams = getClickEventClientXY(clickEvent);
    const cloudEvent = new ClickNotificationCardEvent({
      ...cloudEventSubjectParams,
      ...positionParams,
      notificationId: notification.id,
      isRead: notification.isRead,
    });
    CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    if (!notification.isRead) {
      try {
        await updateNotification(user.token, { ...notification, isRead: true }, ['push_notifications', notification.id, 'users', user.id]);
      } catch (error) {
        // console.log('Error updating message status', error);
      }
    }
  };

  const handleChipClick = (chipStatus, clickEvent) => {
    const positionParams = getClickEventClientXY(clickEvent);
    const cloudEvent = new ClickNotificationSelectableChipEvent({
      ...cloudEventSubjectParams,
      ...positionParams,
      chipStatus,
    });
    CloudEventsApi.sendCloudEvent({ cloudEvent, userToken: user.token });
    setSelectedChip(chipStatus);
  };

  return (
    <div className="notificationsPageV2-container">
      {
        !isMobile && (
          <div className={getResponsiveClassname('notificationsPageV2-header', false, isTablet)}>
            <Typography className="notificationsPageV2-title">
              {translation('NotificationsPageV2.title')}
            </Typography>
            <CloseButtonComponent handleButtonClose={() => history.goBack()} />
          </div>
        )
      }
      <div className="notificationsPageV2-pageContent">
        <div className={getThemedResponsiveClassname('notificationsPageV2-pageContainer-list', isMobile, isTablet)}>
          {
            loading
              ? <NotificationListSkeletonLoader isTablet={isTablet} isMobile={isMobile} />
              : (
                <div>
                  <div className={getResponsiveClassname('notificationsPageV2-chipContainer', isMobile, false)}>
                    <SelectableChipWithCount
                      label={translation('NotificationsPageV2.new')}
                      count={newNotifications.filter(notification => notification.isRead === false).length}
                      isSelected={selectedChip === CHIP.new}
                      onClick={clickEvent => handleChipClick(CHIP.new, clickEvent)}
                    />
                    <SelectableChip
                      label={translation('NotificationsPageV2.read')}
                      isSelected={selectedChip === CHIP.read}
                      onClick={clickEvent => handleChipClick(CHIP.read, clickEvent)}
                    />
                  </div>
                  <Typography className={getResponsiveClassname('notificationsPageV2-chipTitle', isMobile, false)}>
                    {getSelectedChipTitle(selectedChip, translation)}
                  </Typography>
                  <List className={getThemedResponsiveClassname('notificationsPageV2-listContainer', isMobile, false)}>
                    {
                      notifications.length === 0
                        ? (
                          <EmptyNotifications
                            isNewNotificationsSelected={selectedChip === CHIP.new}
                            translation={translation}
                          />
                        )
                        : (
                          notifications.map(notification => (
                            <div key={notification.id}>
                              <ListItemCard
                                key={notification.id}
                                title={notification.subject}
                                body={notification.messageBody}
                                date={DateHelper.getFormattedNotificationDate(notification, translation)}
                                imageURL={notification.imageURL}
                                isSelected={selectedNotificationId === notification.id}
                                isRead={notification.isRead}
                                onClick={clickEvent => handleNotificationClick(notification, clickEvent)}
                                isMobile={isMobile}
                              />
                            </div>
                          ))
                        )
                    }
                  </List>
                </div>
              )
          }
        </div>
        {
          !isResponsiveDesign && (
            <div className="notificationsPageV2-gap" />
          )
        }
        {
          !isResponsiveDesign && (
            <div className={getThemedClassname('notificationsPageV2-pageContainer-view')}>
              {
                loading
                  ? <NotificationViewSkeletonLoader />
                  : (
                    <NotificationCard
                      setError={setError}
                      notification={selectedNotification}
                      translation={translation}
                    />
                  )
              }
            </div>
          )
        }
      </div>
      {
        isResponsiveDesign
        && (
          <NotificationActionSheet
            isOpen={isSingleViewOpen}
            width={width}
            setIsOpen={setIsSingleViewOpen}
            notification={selectedNotification}
            translation={translation}
            setError={setError}
          />
        )
      }
    </div>
  );
};

NotificationsComponent.propTypes = {
  translation: func.isRequired,
  history: objectOf(any).isRequired,
  upliftHistory: func.isRequired,
  user: objectOf(any),
  width: oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  newNotifications: arrayOf(objectOf(any)),
  readNotifications: arrayOf(objectOf(any)),
  updateNotification: func.isRequired,
  setError: func.isRequired,
};

NotificationsComponent.defaultProps = {
  user: null,
  newNotifications: [],
  readNotifications: [],
};

const mapStateToProps = state => ({
  newNotifications: getNewNotifications(state),
  readNotifications: getReadNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  updateNotification: (apiToken, notification, resourcePath) => dispatch(Actions.updateNotification(apiToken, notification, resourcePath)),
  setError: message => dispatch(Actions.setErrorSnackbarMessage(message)),
});

const NotificationsPage = compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(withWidth()(NotificationsComponent)));

export default NotificationsPage;
