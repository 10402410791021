import React from 'react';
import { CardMedia, Typography } from '@material-ui/core';
import { objectOf, func, any } from 'prop-types';
import DateHelper from '../../../services/helpers/DateHelper';

import '../../../css/notificationsPageV2/components/NotificationCard.scss';
import getThemedClassname from '../../../utils/themedClassnames';
import Button from '../../core/components/Button';
import { useHandleClickCTA } from '../../../events/Notifications/handleClickCTA';
import SelectAMessage from './SelectAMessage';

const NotificationCard = (props) => {
  const {
    notification,
    translation,
    setError,
  } = props;
  if (!notification) return <SelectAMessage translation={translation} />;

  const {
    subject,
    messageBody,
    imageURL,
    link,
    linkText,
  } = notification;

  const handleClickCTA = useHandleClickCTA();

  return (
    <div>
      {
        Boolean(imageURL) && (
          <CardMedia
            className="notification-card-image"
            component="img"
            src={imageURL}
          />
        )
      }
      <div className={imageURL ? 'notification-card-content-with-image' : 'notification-card-content'}>
        <Typography className="notification-card-title">
          {subject}
        </Typography>
        <Typography className={getThemedClassname('notification-card-date')}>
          {DateHelper.getFormattedNotificationDate(notification, translation)}
        </Typography>
        <Typography className={getThemedClassname('notification-card-body')}>
          {messageBody}
        </Typography>
        {
          Boolean(link) && (
            <Button
              className="notification-card-cta"
              type="primary"
              onClick={clickEvent => handleClickCTA({
                clickEvent,
                url: link,
                id: notification.id,
                setError,
                translation,
              })}
              text={linkText}
              overrideClass
              keepclickeventonclick
            />
          )
        }
      </div>
    </div>
  );
};

NotificationCard.propTypes = {
  notification: objectOf(any),
  translation: func.isRequired,
  setError: func.isRequired,
};

NotificationCard.defaultProps = {
  notification: null,
};

export default NotificationCard;
