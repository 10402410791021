import React from 'react';
import { CardMedia, Typography } from '@material-ui/core';
import { func } from 'prop-types';

import '../../../css/notificationsPageV2/components/SelectAMessage.scss';
import { getSharedWebAssetUrl } from '../../../services/functions/Functions';
import getThemedClassname from '../../../utils/themedClassnames';

const SelectAMessage = ({ translation }) => {
  const imageSrc = getSharedWebAssetUrl('craver_bell_icon.png');
  const text = translation('NotificationsPageV2.selectAMessage');

  return (
    <div className="select-a-message">
      <CardMedia
        className="select-a-message-cardMedia"
        component="img"
        src={imageSrc}
      />
      <Typography className={getThemedClassname('select-a-message-text')}>{text}</Typography>
    </div>
  );
};

SelectAMessage.propTypes = {
  translation: func.isRequired,
};

export default SelectAMessage;
