class DateHelper {
  static addMinutesToDate = (date, minutes) => {
    const dateToUse = new Date(date.toString());
    dateToUse.setMinutes(dateToUse.getMinutes() + minutes);
    return dateToUse;
  }

  static sameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate();
  }

  /**
  * A leading 0 is needed for minutes < 10 because they are sometimes stored as a
  * single digit, i.e 5:05 stored as 5:5 in the db, 5:00 is 5:0, etc.
  */
  static convertMinutes(minutes) {
    return (minutes < 10 && minutes.length === 1) ? `0${minutes}` : `${minutes}`;
  }

  /**
   * Converts '15:34' to '3:34 PM'
   * Converts '11:34' to '11:34 AM'
   * @param {String} timeString: like '15:34'
   */
  static convertToAmPm = (timeString = '') => {
    if (!timeString || timeString.length === 0) return undefined;
    const [hours, minutes] = timeString.split(':');
    const hoursInt = parseInt(hours, 10);

    const fullMinutes = minutes === 0 ? '00' : DateHelper.convertMinutes(minutes);

    const isMidnight = hoursInt === 0;
    const isUsing24HourFormat = hoursInt > 12;
    const isPM = hoursInt >= 12;

    let hoursToUse;
    if (isMidnight) hoursToUse = 12;
    else if (isUsing24HourFormat) hoursToUse = hoursInt - 12;
    else hoursToUse = hoursInt;

    const suffix = isPM
      ? 'PM'
      : 'AM';
    return `${hoursToUse}:${fullMinutes}\u0020${suffix}`;
  }

  static getBeginningOfHour = (date) => {
    const dateToUse = new Date(date.toString());
    dateToUse.setMilliseconds(0);
    dateToUse.setSeconds(0);
    dateToUse.setMinutes(0);
    return dateToUse;
  }

  static getDateMinusYear = (datetime) => {
    const dateString = datetime.toDateString();
    const pieces = dateString.split(' ');
    const [dayOfWeek, month, day] = pieces;
    return `${dayOfWeek}, ${month} ${day}`;
  }

  // Separator is like 'AT' or other translation...
  static getSuggestedTimeIntervalString = (epochSeconds, separator) => {
    const datetime = new Date(epochSeconds * 1000);
    const date = DateHelper.getDateMinusYear(datetime);
    const time = DateHelper.getTimeMinusSeconds(datetime);
    const timeWithSuffix = DateHelper.convertToAmPm(time);
    return `${date} ${separator} ${timeWithSuffix}`;
  }

  static getDateMonthAndDay = (datetime) => {
    const dateString = datetime.toDateString();
    const pieces = dateString.split(' ');
    const month = pieces[1];
    const day = pieces[2];
    return `${month} ${day}`;
  }

  // Separator is like 'AT' or other translation...
  static getSuggestedTimeIntervalStringV2 = (epochSeconds, separator) => {
    const datetime = new Date(epochSeconds * 1000);
    const date = DateHelper.getDateMonthAndDay(datetime);
    const time = DateHelper.getTimeMinusSeconds(datetime);
    const timeWithSuffix = DateHelper.convertToAmPm(time).toLowerCase();
    return `${date} ${separator} ${timeWithSuffix}`;
  }

  static getTimeMinusSeconds = (datetime) => {
    const timeString = datetime.toTimeString().split(' ')[0];
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  }

  static isAfter = (date, compareToDate) => date.getTime() > compareToDate.getTime();

  static parseEpochToDate = (epoch) => {
    const date = new Date(epoch);
    if (date.getFullYear() === 1970) return new Date(epoch * 1000);
    return date;
  }

  static parseEpochToSeconds = (epoch) => {
    const date = new Date(epoch);
    if (date.getFullYear() === 1970) return epoch;
    return epoch / 1000;
  }
  static minutesBetweenEpochs = (epoch1, epoch2) => {
    const difference = epoch1 - epoch2;
    return Math.round(difference / 60000);
  }

  static parseDateTimeStringToTimestamp = (timeString) => {
    const date = new Date(timeString);
    return Math.floor(date.getTime() / 1000);
  };

  static getFormattedNotificationDate = (notification, translation) => DateHelper.getSuggestedTimeIntervalStringV2(
    DateHelper.parseDateTimeStringToTimestamp(notification.sentAt),
    translation('NotificationsPageV2.notification.star'),
  );
}

export default DateHelper;
