import React from 'react';
import ContentLoader from 'react-content-loader';
import '../../../css/notificationsPageV2/components/NotificationViewSkeletonLoader.scss';
import { isDarkTheme } from '../../../services/functions/Functions';

const NotificationViewSkeletonLoader = () => {
  const skeletonContentHeight = 105;
  const skeletonContentWidth = 555;

  return (
    <div className="notificationViewSkeletonLoader-itemContainer">
      <ContentLoader
        speed={2}
        viewBox={`0 0 ${skeletonContentWidth} ${skeletonContentHeight}`}
        height={skeletonContentHeight}
        width={skeletonContentWidth}
        backgroundColor={isDarkTheme() ? '#787878' : '#d8d8d8'}
        foregroundColor={isDarkTheme() ? '#a0a0a0' : '#ffffff'}
      >
        <rect x="0" y="0" rx="17" ry="50" width="551" height="34" />
        <rect x="0" y="58" rx="7" ry="50" width="455" height="16" />
        <rect x="0" y="86" rx="7" ry="50" width="302" height="16" />
      </ContentLoader>
    </div>
  );
};

export default NotificationViewSkeletonLoader;
