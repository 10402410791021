import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import isEmpty from 'lodash/isEmpty';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import LoginDialog from '../authentication/LoginDialog';
import SignUpDialog from '../authentication/SignUpDialog';
import VerifyDialog from '../authentication/VerifyDialog';
import ResetPasswordDialog from '../authentication/ResetPasswordDialog';
import RewardDialog from '../core/components/RewardDialog';
import GuestSignInDialog from '../authentication/GuestSignInDialog';
import '../../css/core/CoreView.scss';
import { FeatureFlags } from '../../../src/services/functions/FeatureFlag';
import * as Routes from '../../services/routes/Routes.json';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import * as Functions from '../../services/functions/Functions';
import * as Constants from '../../services/constants/Constants';
import * as jsonValues from '../../../jsonStyles/components/core/CoreView.style.json';
import HeaderLinks, { KEYS } from '../../config/header';
import HeaderNavToSubscriptionPage from '../../events/Subscription/HeaderNavToSubscriptionPage';
import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import { DIALOG_CONSTANTS, DIALOG_EVENT_SOURCES } from './DialogConstants';

const innerContentImageDesktopStyle = {
  backgroundImage: `url(${Functions.getImageUrl('background.png')})`,
  backgroundRepeat: jsonValues.desktop.background.backgroundRepeat,
  backgroundSize: jsonValues.desktop.background.backgroundSize,
  backgroundAttachment: jsonValues.desktop.background.backgroundAttachment,
};

const innerContentImageMobileStyle = {
  backgroundImage: `url(${Functions.getImageUrl('background.png')})`,
  backgroundRepeat: jsonValues.mobile.background.backgroundRepeat,
  backgroundSize: jsonValues.mobile.background.backgroundSize,
  backgroundAttachment: jsonValues.mobile.background.backgroundAttachment,
  marginTop: jsonValues.mobile.innerContent.marginTop,
};

const innerContentMobileStyle = {
  marginTop: jsonValues.mobile.innerContent.marginTop,
};

const initialState = history => ({
  selectedPage: history.location.pathname,
  openLoginDialog: false,
  openSignUpDialog: false,
  openVerifyDialog: false,
  openResetPasswordDialog: false,
  openRewardsDialog: false,
  openGuestSignInDialog: false,
  userEmail: '',
  appDrawerOpen: false,
  cartIconWasPressed: false,
});

const updateStateReducer = (prevState, nextState) => {
  const newState = { ...prevState, ...nextState };
  // Run some validations here ...
  return newState;
};

const CoreView = ({
  children, user, translation, actions, locations, rememberUser, currentOrder, dialogLoading, history, width,
}) => {
  const [state, updateState] = useReducer(updateStateReducer, initialState(history));

  const {
    selectedPage, openLoginDialog, openSignUpDialog, openVerifyDialog, openResetPasswordDialog, userEmail,
    openRewardsDialog, openGuestSignInDialog, appDrawerOpen, cartIconWasPressed,
  } = state;

  useEffect(() => {
    if (locations.length === 0) {
      try {
        // eslint-disable-next-line no-undef
        actions.getAllResources(window.companyApiKey, ['locations']);
      } catch (error) {
        console.log('API call error', error);
      }
    }
  }, []);

  // Handle user prop update.
  useEffect(() => {
    if (!user) return;
    try {
      actions.getAllResources(
        user.token,
        ['users', user.id, 'addresses'],
      );
    } catch (error) {
      console.log('API call error', error);
    }
    // If user has logged in, fetch user's notifications
    try {
      actions.getUserNotifications(
        user.token,
        ['messaging', 'v1', 'push-notifications', 'users', user.id],
      );
    } catch (error) {
      console.log('API error retrieving user notifications', error);
    }
  }, [user]);

  // Handle currentOrder prop update.
  useEffect(() => {
    const orderObj = {};

    // TO-DO: Might need to remove this showNewFlow flag.
    if (!FeatureFlags.MenuPage.showNewFlow) {
      // Set default location
      if (!currentOrder.location && locations.length > 0) [orderObj.location] = locations;

      // Set default deliveryOption
      if (!currentOrder.deliveryOption) orderObj.deliveryOption = 'PICKUP';

      // Set default isASAP as true only if isASAP is null or undefined
      // If user hits the back button between checkout and payment page and
      // isASAP is false, the currentOrder.isASAP should persist that false state
      if (currentOrder.isASAP === null || currentOrder.isASAP === undefined) orderObj.isASAP = true;
    }

    const deliveryOptions = Functions.availableDeliveryOptions();
    if (!currentOrder.deliveryOption && deliveryOptions.length === 1) {
      [orderObj.deliveryOption] = deliveryOptions;
    }

    if (!isEmpty(orderObj)) {
      actions.createOrder(null, {
        ...currentOrder,
        ...orderObj,
      });
    }
  }, [currentOrder]);

  const onSandwichClick = () => {
    updateState({ appDrawerOpen: true });
  };

  const onNavigateClose = () => {
    updateState({ appDrawerOpen: false });
  };

  const getFooterOptions = () => {
    // eslint-disable-next-line no-undef
    const companyName = window.companyName || '';
    const option1Text = `${companyName.toUpperCase()}'S TERMS OF USE`;
    const option2Text = `${companyName.toUpperCase()}'S PRIVACY STATEMENT`;
    return (
      [
        {
          id: 1, title: option1Text, ref: Routes.path.termsPage,
        },
        {
          id: 2, title: option2Text, ref: Routes.path.privacyPage,
        },
      ]
    );
  };

  const handleClickAuthentication = (updateParentStateObj) => {
    if (user) {
      // eslint-disable-next-line no-undef
      window.localStorage.clear();
      actions.logoutUser();
      return;
    }
    const newState = updateParentStateObj || {};
    newState.openLoginDialog = true;
    updateState(newState);
  };

  const cloudEventsSubjectParams = useCloudEventsBaseParams();

  const userToken = user && user.token;

  const handleAction = (type, clientPosition) => {
    // Subscriptions link
    if (type === KEYS.SUBSCRIPTIONS) {
      const cloudEvent = new HeaderNavToSubscriptionPage({
        ...cloudEventsSubjectParams,
        ...clientPosition,
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }

    if (type.toUpperCase() === translation('CoreView.header.option4.title')) {
      updateState({
        openRewardsDialog: true,
      });
      return;
    }

    // This is only triggered by LoginDialog's Create Account link, when searching the codebase
    if (type === translation('HeaderComponent.createAccount')) {
      updateState({
        openLoginDialog: false,
        openSignUpDialog: true,
      });
      return;
    }

    if (type === 'sign_out') {
      handleClickAuthentication();
      return;
    }

    updateState({ selectedPage: type });
    history.push(`/${type}`);
  };

  const handleDialogClose = (dialogObj = { dialog: null }) => {
    const authenticationInProgress = Constants.authenticationStillInProgressEvents.includes(dialogObj.eventSource);
    const isStillFromLastCartIconPress = cartIconWasPressed && authenticationInProgress;

    updateState({
      openLoginDialog: false,
      openSignUpDialog: false,
      openVerifyDialog: false,
      openResetPasswordDialog: false,
      openRewardsDialog: false,
      openGuestSignInDialog: false,
      userEmail: '',
      appDrawerOpen: false,
      cartIconWasPressed: isStillFromLastCartIconPress,
    });

    if (dialogObj.dialog === DIALOG_CONSTANTS.SIGNUP && dialogObj.eventSource === DIALOG_EVENT_SOURCES.SIGNUP_SUCCESS) {
      updateState({
        openVerifyDialog: true,
      });
    } else if (dialogObj.dialog === DIALOG_CONSTANTS.LOGIN) {
      if (dialogObj.openVerifyDialog) {
        // Open verify dialog if the user is not verified
        updateState({
          openVerifyDialog: dialogObj.openVerifyDialog,
          userEmail: dialogObj.userEmail,
        });
      } else if (dialogObj.openResetPasswordDialog) {
        // Open reset password dialog if the user clicks forget password
        updateState({
          openResetPasswordDialog: dialogObj.openResetPasswordDialog,
        });
      } else if (dialogObj.openGuestSignInDialog) {
        updateState({
          openGuestSignInDialog: dialogObj.openGuestSignInDialog,
        });
      }
    } else if (dialogObj.dialog === DIALOG_CONSTANTS.VERIFY) {
      if (dialogObj.eventSource === DIALOG_EVENT_SOURCES.CLOSE_ICON) {
        // Clear the state when the user closes the verify dialog so that they are not logged in
        // But keep the order so that if the item count is 1+, the items are not lost
        actions.logoutUser('verifyCloseIcon');
      }
    } else if (dialogObj.dialog === DIALOG_CONSTANTS.RESET_PASSWORD) {
      if (dialogObj.openVerifyDialog) {
        // Open verify dialog if the user is not verified
        updateState({
          openVerifyDialog: dialogObj.openVerifyDialog,
          userEmail: dialogObj.userEmail,
        });
      }
    }
  };

  const sendUserToCheckout = () => {
    if (!state.cartIconWasPressed) return;
    actions.toggleComponent('CheckoutDrawer');
  };

  const getInnerContentImageStyle = isDesktop => ((isDesktop) ? innerContentImageDesktopStyle : innerContentImageMobileStyle);
  const getInnerContentStyle = isDesktop => ((isDesktop) ? {} : innerContentMobileStyle);

  const isDesktop = Functions.isDesktopMode(width);

  const getHeaderOptions = () => {
    const staticOptions = HeaderLinks.headerLinks;
    if (!user) return staticOptions;

    let headerOptionsExtra = HeaderLinks.headerLinksExtra;

    // Remove the option for rewards if the disableRewards flag is enabled
    if (FeatureFlags.disableRewards) headerOptionsExtra = headerOptionsExtra.filter(opt => opt.key !== 'rewards');

    // Remove the option for subscriptions if the enableSubscription flag is disabled
    if (!FeatureFlags.enableSubscription) headerOptionsExtra = headerOptionsExtra.filter(opt => opt.key !== 'subscriptions');

    // If the enableAccountPage flag is enabled, replace the settingsPage with accountPage
    headerOptionsExtra = FeatureFlags.enableAccountPage ? headerOptionsExtra.filter(opt => opt.key !== 'settings') : headerOptionsExtra.filter(opt => opt.key !== 'account');

    if (user.guestToken) {
      headerOptionsExtra = Functions.filterGuestOptions(headerOptionsExtra);
    }

    return staticOptions.concat(headerOptionsExtra);
  };

  const externalOptions = FeatureFlags.ResourceConstants.headerExternalLinks;

  return (
    <div className="coreView-root">
      <div className="appFrame">
        <main className="coreView-content">
          {
            FeatureFlags.CoreView.HeaderComponent.show
              && (
                <HeaderComponent
                  handleClickOptions={handleAction}
                  handleClickAuthentication={handleClickAuthentication}
                  options={getHeaderOptions()}
                  externalOptions={externalOptions}
                  headersubMenuOptions={[]}
                  user={user}
                  selectedPage={selectedPage}
                  translation={translation}
                  actions={actions}
                  currentOrder={currentOrder}
                  handleDrawerOpen={onSandwichClick}
                  handleDrawerClose={onNavigateClose}
                  appDrawerOpen={appDrawerOpen}
                  history={history}
                />
              )
          }
          {
            FeatureFlags.CoreView.innerContentBackground.useImage
              ? <div className="innerContent" style={getInnerContentImageStyle(isDesktop)}>{children}</div>
              : <div className="innerContent" style={getInnerContentStyle(isDesktop)} >{children}</div>
          }
          {
            FeatureFlags.CoreView.FooterComponent.show
              && (
                <FooterComponent
                  options={getFooterOptions()}
                  translation={translation}
                />
              )
          }
        </main>
        <LoginDialog
          actions={actions}
          open={openLoginDialog}
          cartIconWasPressed={cartIconWasPressed}
          handleClickOptions={handleAction}
          handleClose={handleDialogClose}
          rememberUser={rememberUser}
          translation={translation}
          currentOrder={currentOrder}
          sendUserToCheckout={sendUserToCheckout}
        />
        <SignUpDialog
          actions={actions}
          open={openSignUpDialog}
          handleClose={handleDialogClose}
          translation={translation}
        />
        <VerifyDialog
          actions={actions}
          open={(user && (!user.isVerified && !user.guestToken)) || openVerifyDialog}
          handleClose={handleDialogClose}
          translation={translation}
          user={user}
          userEmail={userEmail}
          currentOrder={currentOrder}
          sendUserToCheckout={sendUserToCheckout}
        />
        <ResetPasswordDialog
          actions={actions}
          open={openResetPasswordDialog}
          handleClose={handleDialogClose}
          translation={translation}
          dialogLoading={dialogLoading}
          sendUserToCheckout={sendUserToCheckout}
          currentOrder={currentOrder}
        />
        <RewardDialog
          open={openRewardsDialog}
          handleClose={handleDialogClose}
          actions={actions}
          user={user}
          translation={translation}
        />
        <GuestSignInDialog
          open={openGuestSignInDialog}
          handleClose={handleDialogClose}
          actions={actions}
          translation={translation}
        />
      </div>
    </div>
  );
};

CoreView.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  width: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  translation: PropTypes.func.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogLoading: PropTypes.number,
};

CoreView.defaultProps = {
  company: null,
  children: null,
  width: null,
  user: null,
  history: null,
  currentOrder: {},
  dialogLoading: 0,
};

export default (withRouter(withWidth()(CoreView)));
