import React, { Fragment, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Immutable from 'seamless-immutable';
import {
  TextField,
  Typography,
  Drawer,
  Divider,
  Icon,
  IconButton,
  CircularProgress,
  Switch,
} from '@material-ui/core';
import {
  func,
  objectOf,
  bool,
  any,
  arrayOf,
} from 'prop-types';

import Button from '../core/components/Button';
import LinkText from '../core/components/LinkText';
import OrderItemList from '../core/components/OrderItemList';
import SubTotalSummary from '../core/components/SubTotalSummary';
import ProductFlowDialog from '../product/ProductFlowDialog';
import TableNumberDropdown from '../core/components/TableNumberDropdown';
import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import ClickCartPayNowButtonEvent from '../../events/Checkout/ClickCartPayNowButtonEvent';
import getClickEventClientXY from '../../events/utils/getClickEventClientXY';

import {
  getCurrentOrder,
  getCurrentOrderItems,
  getDollarRewardCoupons,
  getIsProductDialogVisible,
  getIsCheckoutDrawerVisible,
  getProducts,
  getCategories,
  getLoading,
  getLocations,
  getCompany,
  getUserToken,
} from '../../selectors';
import * as Actions from '../../actions/Actions';
import {
  getFormattedPrice,
  isDollarReward,
  isCouponRedeemed,
  getProductFamily,
  isInMenuPage,
  isCurbsideEnabled,
  toFixedPrecision,
  calculatePrice,
  getTableNumbers,
  getProductListFromUpsells,
  refreshOrderPrice,
  getOrderCouponId,
} from '../../services/functions/Functions';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import {
  DINE_IN,
  PICKUP,
  CATERING,
  DELIVERY,
  deliveryOptionIcons,
} from '../../services/constants/Constants';
import DateHelper from '../../services/helpers/DateHelper';
import { FeatureFlags } from '../../services/functions/FeatureFlag';

import '../../css/checkout/CheckoutDrawer.scss';
import UpsellCarrousel from './subComponents/UpsellCarrousel';
import ClickApplyCouponButtonEvent from '../../events/Checkout/ClickApplyCouponButtonEvent';

const TimeLocationSelector = (props) => {
  const {
    translation,
    currentOrder,
    actions,
    isInMenuPage,
  } = props;

  const {
    desiredTime,
    deliveryOption,
  } = currentOrder;

  const atText = translation('at');

  const getLocationText = () => {
    if ([DINE_IN, PICKUP].includes(deliveryOption)) {
      return get(currentOrder, 'location.address', '');
    }
    return get(currentOrder, 'address.streetAddress', '');
  };

  const getDesiredTime = () => {
    if (desiredTime == null) return '';
    const desiredDateText = DateHelper.getSuggestedTimeIntervalString(desiredTime, '-').toUpperCase();
    return `${atText} ${desiredDateText}`;
  };

  const showOrderFlowDialog = () => {
    actions.toggleComponent('OrderFlowDialog');
  };

  const removeUnderscores = text => text && text.replace(/_/g, ' ');

  return (
    <div className="checkoutDrawer-timeLocationContainer">
      <div className="checkoutDrawer-timeLocation">
        <div className="checkoutDrawer-timeContainer">
          <Icon>{deliveryOptionIcons[deliveryOption]}</Icon>
          <Typography className="checkoutDrawer-timeLocationTitle">
            {`${removeUnderscores(deliveryOption) || translation('CheckoutDrawer.emptyDeliveryOption')} ${getDesiredTime()}`}
          </Typography>
        </div>
        <Typography className="checkoutDrawer-addressText">
          {getLocationText()}
        </Typography>
      </div>
      {
        isInMenuPage
          && (
            <LinkText
              className="checkoutDrawer-linkText"
              onClick={showOrderFlowDialog}
              id="changeOrder"
              text={translation('CheckoutDrawer.orderLocationSelector.change')}
            />
        )
      }
    </div>
  );
};

const EmptyCart = (props) => {
  const {
    translation,
  } = props;
  return (
    <div className="checkoutDrawer-emptyOrderContainer">
      <div className="checkoutDrawer-iconContainer">
        <Icon className="checkoutDrawer-emptyCartIcon">shopping_cart</Icon>
      </div>
      <Typography className="checkoutDrawer-emptyCartText">{translation('CheckoutDrawer.emptyCart')}</Typography>
      <Typography className="checkoutDrawer-addItemsText">{translation('CheckoutDrawer.emptyCartSubTitle')}</Typography>
    </div>
  );
};

const CouponInput = (props) => {
  const {
    isCouponInputVisible, applyCoupon, translation,
  } = props;
  const [couponCode, setCouponCode] = useState('');

  if (!isCouponInputVisible) return null;
  return (
    <Fragment>
      <Divider />
      <form className="checkoutDrawer-couponForm" noValidate autoComplete="off">
        <TextField
          className="checkoutDrawer-couponInput"
          id="coupon-input"
          variant="outlined"
          placeholder={translation('CheckoutDrawer.couponInput')}
          onChange={event => setCouponCode(event.target.value)}
        />
        <Button
          type="primary"
          id="applyCoupon"
          keepclickeventonclick
          onClick={(clickEvent) => applyCoupon(couponCode, clickEvent)}
          text={translation('CheckoutDrawer.orderDetails.apply')}
        />
      </form>
      <Divider />
    </Fragment>
  );
};

const DollarRewardButton = (props) => {
  const {
    currentOrder, coupon, translation, removeCoupon, applyCoupon, currentOrderCouponId
  } = props;

  if (!isDollarReward(coupon)) return null;
  const couponRedeemed = isCouponRedeemed(coupon, currentOrder);
  const buttonText = couponRedeemed
    ? translation('CheckoutDrawer.orderDetails.unredeem')
    : translation('CheckoutDrawer.orderDetails.use');

  // If we already have a redeemed coupon, then don't show the button.
  const hideDollarRewardButton = currentOrderCouponId && currentOrderCouponId !== coupon.id && !couponRedeemed;
  if (hideDollarRewardButton) return null;

  const onClickButton = couponRedeemed
    ? () => removeCoupon(coupon)
    : () => applyCoupon(coupon.code);
  return (
    <Button
      type="secondary"
      onClick={onClickButton}
      text={buttonText}
    />
  );
};

const RemoveButton = (props) => {
  const {
    coupon, removeCoupon,
  } = props;
  const buttonText = 'Remove Coupon';
  if (isDollarReward(coupon)) return null;
  return (
    <Button
      type="primary"
      onClick={() => removeCoupon(coupon)}
      text={buttonText}
    />
  );
};

const Coupon = (props) => {
  const { coupon } = props;
  const { code } = coupon;
  const couponText = isDollarReward(coupon)
    ? coupon.title
    : `Coupon code: ${code}`;
  return (
    <div className="checkoutDrawer-couponItem">
      <Typography className="checkoutDrawer-couponText">
        {couponText}
      </Typography>
      <RemoveButton {...props} coupon={coupon} />
      <DollarRewardButton {...props} coupon={coupon} />
    </div>
  );
};

const CouponList = (props) => {
  const {
    currentOrder, actions, user,
  } = props;

  if (user) {
    useEffect(() => {
      actions.getAllResources(user.token, ['coupons']);
    }, []);
  }

  const getAvailableRewardCoupons = (coupons) => {
    const userPoints = user && user.points;
    return coupons.filter((coupon) => {
      const couponPoints = get(coupon, 'rewardTiers.points', null);
      return couponPoints && userPoints >= couponPoints;
    });
  };

  const orderCoupons = get(currentOrder, 'coupons', []).filter(coupon => !isDollarReward(coupon));
  const allDollarRewardCoupons = get(props, 'dollarRewardCoupons', []);
  const availableRewardCoupons = getAvailableRewardCoupons(allDollarRewardCoupons);
  const allCoupons = [...orderCoupons, ...availableRewardCoupons];
  const currentOrderCouponId = getOrderCouponId(currentOrder, allCoupons);
  return (
    <div className="checkoutDrawer-couponList">
      {
        allCoupons.map(coupon => <Coupon {...props} key={coupon.id} coupon={coupon} currentOrderCouponId={currentOrderCouponId} />)
      }
    </div>
  );
};

const CouponSummary = (props) => {
  const {
    currentOrder, actions, user, translation,
  } = props;

  const [isCouponInputVisible, setIsCouponInputVisible] = useState(false);

  const toggleCouponInput = () => {
    setIsCouponInputVisible(!isCouponInputVisible);
  };

  const cloudEventBaseParams = useCloudEventsBaseParams();

  const applyCoupon = (couponCode, clickEvent) => {
    if (clickEvent) {
      const cloudEvent = new ClickApplyCouponButtonEvent({
        ...cloudEventBaseParams,
        ...getClickEventClientXY(clickEvent),
        couponCode,
      });
      CloudEventsApi.sendCloudEvent({
        userToken: user && user.token,
        cloudEvent,
      });
    }
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    const mutableCoupons = Array.isArray(mutableOrder.coupons) ? mutableOrder.coupons : [];
    if (isEmpty(couponCode)) return null;
    const coupons = [ ...mutableCoupons, { code: couponCode }];
    mutableOrder.coupons = coupons;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  return (
    <Fragment>
      <CouponList
        {...props}
        applyCoupon={applyCoupon}
        removeCoupon={(coupon) => {
          actions.deleteOrderCoupon(user, currentOrder, coupon.id);
        }}
      />
      <CouponInput
        {...props}
        isCouponInputVisible={isCouponInputVisible}
        applyCoupon={(couponCode, clickEvent) => applyCoupon(couponCode, clickEvent)}
      />
      {
        !isCouponInputVisible && (
          <LinkText
            id="toggleCouponInput"
            onClick={toggleCouponInput}
            className="checkoutDrawer-addCouponLink"
            text={`+ ${translation('CheckoutDrawer.addCoupon')}`}
          />
        )
      }
    </Fragment>
  );
};

const TotalPrice = (props) => {
  const { currentOrder, translation } = props;
  const orderTotalPrice = get(currentOrder, 'totalPrice') || 0;
  const tipAmount = get(currentOrder, 'tipAmount') || 0;
  const priceText = getFormattedPrice(orderTotalPrice - tipAmount);
  return (
    <div className="checkoutDrawer-totalPriceContainer">
      <Divider />
      <div className="checkoutDrawer-totalPriceItem">
        <Typography className="checkoutDrawer-totalPriceText">
          {translation('CheckoutDrawer.summary.total')}
        </Typography>
        <Typography className="checkoutDrawer-totalPriceText" >{priceText}</Typography>
      </div>
      <Divider />
    </div>
  );
};

const InstructionsBox = (props) => {
  const {
    actions, translation, user, currentOrder,
  } = props;

  const [orderNote, setOrderNote] = useState(currentOrder.note);

  const updateOrderNote = () => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.note = orderNote;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const { instructionsBoxLines } = FeatureFlags.ResourceConstants;

  return (
    <div className="checkoutDrawer-instructionBox">
      <Typography className="checkoutDrawer-instructionTitle">{translation('CheckoutDrawer.orderDetails.note')}</Typography>
      <TextField
        className="checkoutDrawer-instructionsInput"
        id="instructions-input"
        variant="outlined"
        onChange={event => setOrderNote(event.target.value)}
        value={orderNote}
        onBlur={updateOrderNote}
        minRows={instructionsBoxLines}
        multiline
      />
    </div>
  );
};

const CurbsideInstructions = (props) => {
  const {
    translation,
    currentOrder,
    actions,
    user,
  } = props;

  const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
  const showToggle = FeatureFlags.CheckoutDrawer.curbsidePickupToggleShow;
  const defaultCurbsideState = mutableOrder.isCurbsidePickUp != null ? mutableOrder.isCurbsidePickUp : FeatureFlags.CheckoutDrawer.curbsidePickupToggleDefault;

  const [toggleState, setToggleState] = useState({
    curbsideChecked: defaultCurbsideState,
  });

  const handleToggleChange = (event) => {
    setToggleState({ ...toggleState, [event.target.name]: event.target.checked });
    mutableOrder.isCurbsidePickUp = !toggleState.curbsideChecked;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  const [curbsideNote, setCurbsideNote] = useState(currentOrder.vehicleInformation);

  const updateCurbsideInstructions = () => {
    mutableOrder.vehicleInformation = curbsideNote;
    mutableOrder.isCurbsidePickUp = true;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  const { curbsideBoxLines, curbsideInstructionsCharLimit } = FeatureFlags.ResourceConstants;

  return (
    <div data-testid="curbsideToggle">
      {
        <div className="checkoutDrawer-curbsideToggleContainer">
          <Typography className="checkoutDrawer-curbsideToggleLabel">
            {translation('CheckoutDrawer.curbsidePickup.curbsidePickup')}
          </Typography>
          {
            showToggle
            && (
              <Switch
                checked={toggleState.curbsideChecked}
                onChange={handleToggleChange}
                name="curbsideChecked"
                color="primary"
              />
            )
          }
        </div>
      }
      {
        toggleState.curbsideChecked
        && (
          <div className="checkoutDrawer-instructionBox">
            <Typography className="checkoutDrawer-instructionTitle">
              {translation('CheckoutDrawer.curbsidePickup.vehicleInformation')}
            </Typography>
            <TextField
              className="checkoutDrawer-instructionsInput"
              id="vehicle-input"
              variant="outlined"
              onChange={event => setCurbsideNote(event.target.value)}
              value={curbsideNote}
              onBlur={updateCurbsideInstructions}
              minRows={curbsideBoxLines}
              multiline
              placeholder={translation('CheckoutDrawer.curbsidePickup.instructionsPlaceholder')}
              inputProps={{ maxLength: curbsideInstructionsCharLimit }}
            />
          </div>
        )
      }
    </div>
  );
};

const CartContent = (props) => {
  const {
    locations, currentOrder = {}, translation, actions, user, upsellItems,
  } = props;
  const curbsideEnabled = currentOrder.deliveryOption === PICKUP && isCurbsideEnabled(locations, currentOrder);
  const orderItems = get(currentOrder, 'items');
  const tableNumbers = currentOrder.location ? getTableNumbers(currentOrder.location) : [];
  const isDineIn = currentOrder.deliveryOption === DINE_IN;

  if (isEmpty(orderItems)) return <EmptyCart {...props} />;
  return (
    <Fragment>
      <OrderItemList
        handleRemove={props.handleRemove}
        handleEdit={props.handleEdit}
        actions={props.actions}
        orderItems={props.orderItems}
        translation={props.translation}
        isBelowMinimum={props.isBelowMinimum}
      />
      {
        FeatureFlags.enableUpsell
        && (
          <UpsellCarrousel
            upsellItems={upsellItems}
            setUpsell={props.setUpsell}
            actions={props.actions}
            translation={props.translation}
          />
        )
      }
      <SubTotalSummary
        translation={props.translation}
      />
      <Divider />
      <CouponSummary
        {...props}
      />
      <TotalPrice
        {...props}
      />
      {
        curbsideEnabled
        && (
          <CurbsideInstructions
            {...props}
          />
        )
      }
      {
        isDineIn && (tableNumbers.length > 0)
        && (
          <TableNumberDropdown
            value={currentOrder.tableNumber}
            onChange={(event) => { actions.updateOrder(user, { ...currentOrder, tableNumber: event.target.value }, currentOrder.id); }}
            tableNumbers={tableNumbers}
            translation={translation}
          />
        )
      }
      {
        FeatureFlags.CheckoutDrawer.enableInstructionBox
        && (
          <InstructionsBox
            {...props}
          />
        )
      }
    </Fragment>
  );
};

const LoadingOverlay = () => (
  <div className="checkoutDrawer-loadingOverlay">
    <div className="checkoutDrawer-circularProgressContainer">
      <CircularProgress />
    </div>
  </div>
);

const CheckoutDrawer = (props) => {
  const {
    actions,
    isDesktop,
    open,
    currentOrder = {},
    history,
    handleClickAuthentication,
    user,
    translation,
    stateVisibleProductDialog,
    products,
    loading,
    company,
  } = props;
  const { deliveryZone, cateringZone, deliveryOption } = currentOrder;

  let isBelowMinimum = false;

  const calculateSubTotalWithoutRedeem = () => {
    let subTotalResult = 0;
    if (currentOrder && currentOrder.items && products) {
      currentOrder.items.forEach((item) => {
        const itemTotal = calculatePrice([item], products, null, company);
        subTotalResult = itemTotal + subTotalResult;
      });
    }
    return subTotalResult;
  };

  const calculateIfBelowMinimum = () => {
    const zoneMinimum = deliveryOption === DELIVERY
      ? deliveryZone && deliveryZone.minimum
      : cateringZone && cateringZone.minimum;
    const belowMinimumFee = deliveryOption === DELIVERY
      ? deliveryZone && deliveryZone.belowMinimumFee
      : cateringZone && cateringZone.belowMinimumFee;
    const subTotalWithoutRedeem = calculateSubTotalWithoutRedeem();
    // If belowMinimumFee is null, then the zone minimum must be met to place an order
    return subTotalWithoutRedeem < zoneMinimum && !belowMinimumFee;
  };

  if ([CATERING, DELIVERY].includes(deliveryOption)) isBelowMinimum = calculateIfBelowMinimum();

  const orderItems = get(currentOrder, 'items');
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [selectedUpsell, setSelectedUpsell] = useState(null);
  const [upsellItems, setUpsellItems] = useState([]);

  const getUpsellItems = async () => {
    if (!user) {
      setUpsellItems([]);
      return;
    }
    const resourceRequest = await actions.getOfflineResource(user.token, ['users', user.id, 'orders', currentOrder.id, 'upsells?types=COMPLEMENTARY_OFFER']);
    if (!resourceRequest.error) {
      const responseData = JSON.parse(resourceRequest.data);
      const upsellWithProducts = getProductListFromUpsells(responseData.upsells, products);
      setUpsellItems(upsellWithProducts);
    }
  };

  useEffect(() => {
    // declare the data fetching function before call.
    const fetchOrder = async () => {
      if (currentOrder.id) {
        await refreshOrderPrice(actions, user, currentOrder);
      } else {
        const newOrder = await actions.createOrder(user, currentOrder, currentOrder.price);
        if (newOrder && newOrder.id) await actions.updateOrder(user, newOrder, newOrder.id);
      }
    };

    if (!user) return;
    if (open) {
      fetchOrder();
    }
  }, [open]);

  useEffect(() => {
    const fetchUpsellItems = async () => {
      if (open && FeatureFlags.enableUpsell) {
        getUpsellItems();
      }
      await refreshOrderPrice(actions, user, currentOrder);
    };

    fetchUpsellItems();
  }, [open, props.currentOrderItems]);

  useEffect(() => {
    if (open && !user) handleClickAuthentication();
  }, [open, user]);

  const removeItem = (orderItem) => {
    const orderItemId = orderItem.id;
    // TO-DO: Handle redeemed items being removed.
    actions.deleteOrderItem(user, orderItemId, currentOrder.id).then(() => {
      refreshOrderPrice(actions, user, currentOrder);
    });
  };

  const toggleProductDialog = () => {
    actions.toggleComponent('ProductDialog');
  };

  const setEditItem = (orderItem) => {
    setSelectedUpsell(null);
    toggleProductDialog();
    setSelectedOrderItem(orderItem);
  };

  const setUpsell = (upsellItem) => {
    setSelectedUpsell(upsellItem);
    toggleProductDialog();
  };

  const getZoneMinimum = (zone) => {
    if (zone) return zone.minimum;
    return 0.0;
  };

  const renderOrderBelowMinimumMessage = () => {
    const orderLocationName = currentOrder.location ? currentOrder.location.name : '';
    const zoneMinimum = deliveryOption === DELIVERY
      ? getZoneMinimum(deliveryZone)
      : getZoneMinimum(cateringZone);
    return (
      <div className="checkoutDrawer-belowMinimumMessageContainer">
        <span>
          <Typography className="checkoutDrawer-errorText">
            {
              deliveryOption === DELIVERY
                ? translation('CheckoutDrawer.deliveryMinimumNotMet.warning')
                : translation('CheckoutDrawer.cateringMinimumNotMet.warning')
            }
          </Typography>
          <Typography className="checkoutDrawer-errorTextBold">
            {` ${orderLocationName} `}
          </Typography>
          <Typography className="checkoutDrawer-errorText">{translation('CheckoutDrawer.deliveryMinimumNotMet.minimum')}</Typography>
          <Typography className="checkoutDrawer-errorTextBold">{` $${toFixedPrecision(zoneMinimum, 2)} ${translation('CheckoutDrawer.deliveryMinimumNotMet.beforeTax')} `}</Typography>
          <Typography className="checkoutDrawer-errorText">{translation('CheckoutDrawer.deliveryMinimumNotMet.instruction')}</Typography>
        </span>
      </div>
    );
  };

  const getSelectedProductId = () => {
    if (selectedOrderItem) {
      const productFamily = getProductFamily(products, selectedOrderItem.productItem.id);
      return productFamily ? productFamily.id : null;
    }
    return get(selectedUpsell, 'product.id', null);
  };

  const cloudEventBaseParams = useCloudEventsBaseParams();
  const userToken = useSelector(getUserToken);

  const goToPaymentPage = async (clickEvent) => {
    const cloudEvent = new ClickCartPayNowButtonEvent({
      ...cloudEventBaseParams,
      ...getClickEventClientXY(clickEvent),
    });
    CloudEventsApi.sendCloudEvent({
      userToken,
      cloudEvent,
    });
    actions.toggleComponent('CheckoutDrawer');
    actions.toggleComponent('PaymentDrawer');
  };

  const orderTotalPrice = get(currentOrder, 'totalPrice') || 0;
  const tipAmount = get(currentOrder, 'tipAmount') || 0;

  return (
    <Drawer
      classes={{
        paper: 'checkoutDrawer',
      }}
      open={open}
      onClose={() => actions.toggleComponent('CheckoutDrawer')}
      anchor={isDesktop ? 'right' : 'bottom'}
    >
      {
        isBelowMinimum
        && (
          <div className="checkoutDrawer-unavailableWarningOverlay">
            <div className="checkoutDrawer-unavailableWarningHeader">
              <Icon
                color="error"
                fontSize="small"
              >
                warning
              </Icon>
              <Typography
                className="checkoutDrawer-unavailableWarningTitle"
              >
                {
                  currentOrder.deliveryOption === DELIVERY
                    ? translation('CheckoutDrawer.deliveryMinimumNotMet.title')
                    : translation('CheckoutDrawer.cateringMinimumNotMet.title')
                }
              </Typography>
            </div>
            <Typography>
              {renderOrderBelowMinimumMessage()}
            </Typography>
          </div>
        )
      }
      {
        loading !== 0
        && (
          <LoadingOverlay {...props} />
        )
      }
      <div className="checkoutDrawer-header">
        <div className="checkoutDrawer-titleContainer">
          <Typography className="checkoutDrawer-headerTitle">
            {translation('CheckoutDrawer.drawerTitle')}
          </Typography>
          <IconButton className="checkoutDrawer-closeIcon" onClick={() => actions.toggleComponent('CheckoutDrawer')}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <Divider />
      </div>
      <div className="checkoutDrawer-body">
        <CartContent
          {...props}
          orderItems={orderItems}
          handleEdit={setEditItem}
          handleRemove={removeItem}
          setUpsell={setUpsell}
          isBelowMinimum={isBelowMinimum}
          upsellItems={upsellItems}
        />
      </div>
      <div className="checkoutDrawer-footer">
        <Divider className="checkoutDrawer-footerDivider" />
        <TimeLocationSelector
          {...props}
          isInMenuPage={isInMenuPage(props.history)}
        />
        <Button
          fullWidth
          testId="go-to-payment-button"
          id="goToPayment"
          onClick={goToPaymentPage}
          keepclickeventonclick
          type="primary"
          disabled={isBelowMinimum || isEmpty(orderItems)}
          text={`${translation('CheckoutDrawer.orderDetails.makePayment')} (${currentOrder ? getFormattedPrice(orderTotalPrice - tipAmount) : ''})`}
        />
        <LinkText
          id="continueShopping"
          onClick={() => actions.toggleComponent('CheckoutDrawer')}
          className="checkoutDrawer-shoppingLinkText"
          text={translation('CheckoutDrawer.continueShopping')}
        />
        {
          stateVisibleProductDialog && (selectedOrderItem || selectedUpsell)
          && (
            <ProductFlowDialog
              open={stateVisibleProductDialog}
              user={user}
              actions={actions}
              handleClose={toggleProductDialog}
              translation={translation}
              history={history}
              upsellId={get(selectedUpsell, 'id', null)}
              productId={getSelectedProductId()}
              currentItemFromCheckout={selectedOrderItem}
              isEditFromCheckout={!selectedUpsell}
            />
          )
        }
      </div>
    </Drawer>
  );
};

CheckoutDrawer.propTypes = {
  open: bool.isRequired,
  isDesktop: bool.isRequired,
  translation: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  products: arrayOf(objectOf(any)).isRequired,
  company: objectOf(any),
  user: objectOf(any),
};

CheckoutDrawer.defaultProps = {
  company: {},
  user: null,
};

CurbsideInstructions.propTypes = {
  translation: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  actions: objectOf(func).isRequired,
  user: objectOf(any),
};

CurbsideInstructions.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  open: getIsCheckoutDrawerVisible(state),
  currentOrder: getCurrentOrder(state),
  currentOrderItems: getCurrentOrderItems(state),
  dollarRewardCoupons: getDollarRewardCoupons(state),
  loading: getLoading(state),
  stateVisibleProductDialog: getIsProductDialogVisible(state),
  products: getProducts(state),
  categories: getCategories(state),
  locations: getLocations(state),
  company: getCompany(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
});

const EnhancedCheckoutDrawer = compose(connect(mapStateToProps, mapDispatchToProps)(CheckoutDrawer));
export default EnhancedCheckoutDrawer;
