import handleResponse from '../ResponseHandler';
import { getBaseUrl } from '../helpers/PathUtils';

const hostWithApiVersion = window.api_host;
const baseUrl = getBaseUrl(hostWithApiVersion);

export function generateHeader(token) {
  const apiHeader = {};
  apiHeader.Authorization = `Bearer ${token}`;
  apiHeader['Content-Type'] = 'application/json';
  return apiHeader;
}

export function generatePath(resourcePath) {
  return resourcePath.join('/');
}

function sendNotificationUpdateRequest(userToken, updatedNotification, resourcePath) {
  const notification = {
    title: updatedNotification.title,
    body: updatedNotification.body,
  };
  if (updatedNotification.isRead) {
    notification.isRead = updatedNotification.isRead;
  }
  const path = hostWithApiVersion + generatePath(resourcePath);
  const requestBody = JSON.stringify(notification);
  const request = (
    new Request(path, {
      method: 'PUT',
      headers: generateHeader(userToken),
      body: requestBody,
    })
  );
  return handleResponse(request, requestBody).then(response => response);
}

export default class NotificationsAPI {
  static getNotifications(apiToken, resourcePath) {
    const path = `${baseUrl}/${generatePath(resourcePath)}`;
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  }

  static updateNotificationStatus(userToken, updatedNotification, resourcePath) {
    return sendNotificationUpdateRequest(userToken, updatedNotification, resourcePath, 'PUT');
  }
}
