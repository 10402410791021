import React from 'react';
import {
  CardMedia,
  Typography,
  ListItem,
} from '@material-ui/core';
import { func, string, bool } from 'prop-types';
import getThemedClassname from '../../utils/themedClassnames';
import '../../css/shared/ListItemCardMobile.scss';


const getListItemStyle = (isSelected) => {
  if (isSelected) {
    return getThemedClassname('listItemCardMobileSelected');
  }
  return getThemedClassname('listItemCardMobileUnselected');
};

const ListItemCardMobile = (props) => {
  const {
    isRead,
    isSelected,
    title,
    body,
    date,
    imageURL,
    onClick,
  } = props;

  return (
    <ListItem
      className={getListItemStyle(isSelected)}
      onClick={onClick}
    >
      {
        Boolean(imageURL) && (
          <CardMedia
            className="listItemCardMobileImage"
            component="img"
            src={imageURL}
          />
        )
      }
      <div className="listItemCardMobileOuterCardContainer">
        <div className="listItemCardMobileTitleAndSubtitleContainer">
          {
            !isRead && (
              <div className="listItemCardMobileNewDot" />
            )
          }
          <Typography className={getThemedClassname('listItemCardMobileTitle')}>
            {title}
          </Typography>
          <Typography className={getThemedClassname('listItemCardMobileBody')}>
            {body}
          </Typography>
          <Typography className={getThemedClassname('listItemCardMobileTime')}>
            {date}
          </Typography>
        </div>
      </div>
    </ListItem>
  );
};

ListItemCardMobile.propTypes = {
  onClick: func.isRequired,
  title: string.isRequired,
  body: string.isRequired,
  date: string.isRequired,
  imageURL: string,
  isSelected: bool,
  isRead: bool,
};

ListItemCardMobile.defaultProps = {
  isSelected: false,
  imageURL: '',
  isRead: false,
};

export default ListItemCardMobile;
