/* eslint-disable no-console */
import React from 'react';
import {
  isEmpty,
  cloneDeep,
  flattenDeep,
  get,
  isNumber,
  capitalize,
} from 'lodash';
import Immutable from 'seamless-immutable';

import { Typography } from '@material-ui/core';
import { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import * as Routes from '../../services/routes/Routes.json';
import {
  WEEKDAYARRAY,
  DELIVERY,
  CATERING,
  GROUP,
  PICKUP,
  DINE_IN,
  invertMonthDict,
  GETDAY_FULLDAYS,
  REORDER_CHECK_CASES,
  invertShortMonthDict,
  FULL_DAYS,
} from '../../services/constants/Constants';
import { FeatureFlags } from '../../services/functions/FeatureFlag';

import DateHelper from '../helpers/DateHelper';

export const generateKey = index => (`key${index}`);

/**
 * Function for dynamic sorting
 * @param {*} key
 * @param {*} order
 */
export function compareValues(key, order = 'asc') {
  return ((a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
    // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  });
}

/**
 * Converts time from 24-hour to 12-hr, AM/PM
 * format
 * @param {*} time in 24-hour format like '17:34'
 */
export const getTime = time => DateHelper.convertToAmPm(time);

// Format epoch into proper timestamp for craver-api
export const formatEpochToSeconds = epoch => DateHelper.parseEpochToSeconds(epoch);

// Format epoch into Date object
export const formatEpoch = epoch => DateHelper.parseEpochToDate(epoch);

// return desktop mode
export function isDesktopMode(widthProps) {
  return isWidthUp('md', widthProps);
}

// return tablet mode
export function isTabletMode(widthProps) {
  return isWidthDown('sm', widthProps);
}

export function isMobileMode(widthProps) {
  return isWidthDown('sm', widthProps);
}

// Add minutes to epoch, returns epoch
export function addMinutesToEpoch(epoch, minutes) {
  const date = formatEpoch(epoch);
  const millisecondsToAdd = minutes * 60 * 1000;
  const newDate = new Date(date.getTime() + millisecondsToAdd);

  return newDate.getTime();
}

export const timestampToMs = datum => datum * 1000;

// Prepend hour and mins with 0 if < 10 to get 'hh' and 'mm'
export function getFormattedTime(time) {
  const timeArr = time ? time.split(':') : ['00', '00'];
  let hours = timeArr[0];
  let minutes = timeArr[1];

  hours = (hours < 10)
    ? `0${hours}`
    : hours;

  minutes = (minutes < 10)
    ? `0${minutes}`
    : minutes;

  return `${hours}:${minutes}`;
}

// Returns desired time in 'hh:mm' format
export function getDesiredTimeFromEpoch(epoch, orderTimeZone = null) {
  let date = formatEpoch(epoch);
  // Use the location timezone to get the desiredTime
  if (orderTimeZone) {
    const dateStringForTimeZone = date.toLocaleString('en-US', { timeZone: orderTimeZone });
    date = new Date(dateStringForTimeZone);
  }

  const hrs = date.getHours();
  const mins = date.getMinutes();

  return getFormattedTime(`${hrs}:${mins}`);
}

// Prepend month and day with 0 if < 10 to get 'mm' and 'dd'
export function getFormattedDate(date) {
  const dateArr = date.split('/');
  const year = dateArr[0];
  let month = dateArr[1];
  let day = dateArr[2];

  month = (month < 10)
    ? `0${month}`
    : month;

  day = (day < 10)
    ? `0${day}`
    : day;

  return `${year}/${month}/${day}`;
}

// Returns date in 'yyyy-mm-dd' format
export function getDateFromEpoch(epoch) {
  const date = formatEpoch(epoch);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return getFormattedDate(`${year}/${month}/${day}`);
}

/**
 * Takes street address in comma-delimited string and formats
 * into two lines, (1) street address and (2) city, state/province
 * @param {*} address the street address in comma-delimited string
 */
export function parseAddress(address) {
  const match = address.match(/([^,]*),(.*)/);
  return (
    <span>
      {match ? match[1] : ''} <br />
      {match ? match[2] : ''}
    </span>
  );
}

/**
 * Converts server timestamp to human-readable AM/PM format
 * @param {*} timestamp the server timestamp
 */
export const formatDesiredTime = (timestamp) => {
  // convert timestamp to date format
  const date = new Date(timestamp * 1000);
  let hour = date.getHours();
  let minute = date.getMinutes();
  const amPM = (hour > 11) ? 'PM' : 'AM';

  if (hour > 12) hour -= 12;
  else if (hour === 0) hour = '12';

  if (minute < 10) minute = `0${minute}`;

  const time = `${hour}:${minute} ${amPM}`;

  return time;
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May',
  'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * Converts server timestamp to human-readable date format
 * @param {*} timestamp the server timestamp
 */
export const formatDesiredDate = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp * 1000);

  // Date
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  let day = date.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  const datum = `${day} ${month} ${year}`;
  return `${datum}`;
};

export const toFixedPrecision = function (num, precision) {
  return (+(`${Math.round(+(`${num}e${precision}`))}e${-precision}`)).toFixed(precision);
};

/**
 * Converts server timestamp to yyyy/mm/dd format
 * @param {*} timestamp the server timestamp
 */
export const formatDateString = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  // getMonth returns value 0 - 11, add 1 to get 1 - 12
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}/${month}/${day}`;
};

/**
 * PointsPage
 * Assuming redeemArray is sorted in ascending order, use Binary Search to find the index of the next reward tier.
 * Assumes user.points >= 0
 *
 * e.g. user.points: 85, rewards: [50, 75, 100],
 * Next reward tier is 100
 * Returns index: 2
 *
 * @param {*} redeemArray array of rewards
 * @param {*} points user's points
 * @returns int index
 */
export const getNextRewardIndex = (redeemArray, points) => {
  const n = redeemArray.length;
  // Only one reward tier or points are less than lowest tier
  if (n === 1 || points < redeemArray[0].points) return 0;
  // Points are past the highest tier
  if (points >= redeemArray[n - 1].points) return n - 1;
  let low = 0;
  let high = n - 1;
  while (high >= low) {
    const mid = Math.floor((low + (high - low)) / 2);
    if (points >= redeemArray[mid].points && points < redeemArray[mid + 1].points) return mid + 1;
    if (points < redeemArray[mid].points) high = mid - 1;
    if (points > redeemArray[mid].points) low = mid + 1;
  }
  return -1;
};

/**
 * PointsPage
 * Calculate how much of the circle should be filled
 * Is a percentage of users.points / maxRewards
 * Assumes user.points >= 0 and rewards is sorted in ascending order
 *
 * e.g. user points = 50, rewards = [0, 50, 100]
 * Circle will be filled 50% since 50/100 is 50%.
 *
 * @param {*} redeemArray array of rewards
 * @param {*} points user's points
 * @returns a percentage
 */

export const calculateNormalFillHeight = (redeemArray, points) => {
  const n = redeemArray.length;
  if (n === 0) return 0;
  return (points / redeemArray[n - 1].points) * 100;
};

/**
 * PointsPage
 * Calculate how much of the circle should be filled
 * Is a percentage of user.points to points of next reward
 * Assumes user.points >= 0 and rewards is sorted in ascending order
 *
 * e.g. user.points = 50, rewards = [0, 50, 100]
 * Circle will be filled 0% since user has filled 100% of (0, 50)
 * with 50 pts and must now fill (50, 100) starting at 0 pts.
 *
 * e.g. user.points = 75, , rewards = [0, 50, 100]
 * Circle will be filled 50% since user has filled 100% of (0, 50) with 50 pts
 * and the remainder 25 pts fills half of (50, 100) which is 50%
 *
 * @param {*} redeemArray array of rewards
 * @param {*} points user's points
 * @returns a percentage
 */
export const calculateProportionalFillHeight = (redeemArray, points) => {
  if (redeemArray.length === 0) return 0;
  const nextRewardIndex = getNextRewardIndex(redeemArray, points);
  const nextRewardPoints = redeemArray[nextRewardIndex].points;

  if (nextRewardIndex === 0) return (points / nextRewardPoints) * 100;

  const prevRewardPoints = redeemArray[nextRewardIndex - 1].points;
  const ptsBetweenRewards = nextRewardPoints - prevRewardPoints;

  return ((points - prevRewardPoints) / ptsBetweenRewards) * 100;
};

export function sortOptionsBySection(selectedOptions, section) {
  const options = [];

  selectedOptions.map((option) => {
    if ((!option.section && section === 'Whole') || (option.section !== undefined
      && option.section.toLowerCase() === section.toLowerCase())) {
      const opt = {
        id: option.id,
        sharedId: option.sharedId || 0,
        name: option.name,
        priceAddOn: option.priceAddOn,
        description: option.description ? option.description : '',
        quantity: option.quantity || 1,
      };

      options.push(opt);
    }
  });

  return options;
}

export const getProductFamily = (products, productItemId) => {
  let productCategory;

  if (!isEmpty(products)) {
    productCategory = products.find(product =>
      product.items.some(item => item.id === productItemId));
  }

  return productCategory;
};

const getIncludedOptions = (products, productFamilyId, productItemId) => {
  const product = products.find(prod => prod.id === productFamilyId);
  const productItem = product.items.find(item => item.id === productItemId);
  return productItem.includedOptions || [];
};

const calculateHalfAndHalfPrice = (item, products, includedOptions, company) => {
  const left = (!item.subItems[0])
    ? 0
    : calculatePrice([item.subItems[0]], products, includedOptions, null, company);
  const right = (!item.subItems[1])
    ? 0
    : calculatePrice([item.subItems[1]], products, includedOptions, null, company);
  return Math.max(left, right);
};

const getOptionCustomization = (item, option) => item.productItem.customizations
  .find(cus => cus.options.map(opt => opt.id).includes(option.id));

export const groupOptionsByCustomization = (item) => {
  const optionMap = new Map();

  item.options.forEach((option) => {
    let custName = '';
    if (item.productItem.customizations) {
      const cust = item.productItem.customizations
        .find(cus => cus.options.map(opt => opt.id).includes(option.id));
      if (cust != null) custName = cust.name;
    }
    if (optionMap.has(custName)) {
      optionMap.set(custName, [...optionMap.get(custName), `${option.quantity} x ${option.name}`]);
    } else {
      optionMap.set(custName, [`${option.quantity} x ${option.name}`]);
    }
  });
  return optionMap;
};

/**
 * This function is similar to getSelectedItemQuantities and is used in the
 * mobile version where quantity of customization.option is not adjustable
 * @param {*} item the order item
 * @param {*} customization the customization, i.e. Cheese, Pre-Oven Finish, etc.
 */
const getSelectedOptionsForCustomization = (item, customization) => {
  if (!customization) return 0;

  const customizationOptionIds = customization.options.map(opt => opt.id);
  const selectedOptionsIds = item.options.map(opt => opt.id);

  return selectedOptionsIds.filter(id => customizationOptionIds.includes(id)).length;
};

/**
 * Get the total number of quantities selected for that customization
 * i.e. For pre-oven toppings: 5 artichoke + 4 onion, etc.
 * @param {*} item the order item
 * @param {*} customization the customization, i.e. Cheese, Pre-Oven Finish, etc.
 */
const getSelectedItemQuantities = (item, customization) => {
  if (!customization) return 0;
  const customizationOptionIds = customization.options.map(opt => opt.id);
  // Get array of customization option objects and reduce array to sum of quantities selected
  const selectedOptions = item.options.filter(option => customizationOptionIds.includes(option.id));
  const total = selectedOptions.reduce((sum, opt) => sum + opt.quantity, 0);
  return total;
};

export const calculatePrice = (items, products, notIncluded, company) => {
  const {
    portionCoefficient,
    sidePriceStrategy,
    surcharge,
  } = company;

  let subTotal = 0;

  if (items) {
    items.map((item) => {
      let optionsAddOn = 0;
      let halfAndHalfPrice = 0;
      const productFamily = getProductFamily(products, item.productItem.id);
      if (!productFamily) return 0;
      const includedOptions = getIncludedOptions(products, productFamily.id, item.productItem.id);

      if (productFamily.component === 'HALF_AND_HALF') {
        halfAndHalfPrice = calculateHalfAndHalfPrice(item, products, includedOptions, company);
      }
      if (item.options) {
        item.options.map((option) => {
          const customization = getOptionCustomization(item, option);
          // Total number of quantities selected for that customization
          const selectedOptionQuanities = getSelectedItemQuantities(item, customization);
          const availableFreeOptions = customization ? customization.freeOptions : 0;
          const shouldAddToPrice = selectedOptionQuanities > availableFreeOptions;

          const filteredOptions = notIncluded
            ? notIncluded.filter(includedOpt => includedOpt.id === option.id)
            : includedOptions.filter(includedOpt => includedOpt.id === option.id && option.quantity === 1);
          if ((filteredOptions.length) === 0 && shouldAddToPrice) optionsAddOn += option.priceAddOn * option.quantity;
        });
      }
      if (productFamily.component !== 'HALF_AND_HALF' && item.subItems) {
        const sidePrices = { Left: 0, Right: 0 };

        item.subItems.map(subItem => subItem.options.map((option) => {
          const filteredOptions = includedOptions
            .filter(includedOpt => includedOpt.id === option.id);
          if (filteredOptions.length === 0) {
            // subItem.portion.name is 'Left' or 'Right'
            const addOnPrice = sidePriceStrategy === 'PORTION'
              ? (option.priceAddOn * portionCoefficient) + surcharge
              : (option.priceAddOn * 0.5);
            sidePrices[subItem.portion.name] += addOnPrice;
          }
        }));
        const addOn = sidePriceStrategy === 'MAX'
          ? Math.max(sidePrices.Left, sidePrices.Right)
          : sidePrices.Left + sidePrices.Right;
        optionsAddOn += addOn;
      }
      subTotal = (isNumber(item.productItem.price))
        ? ((item.productItem.price + optionsAddOn + halfAndHalfPrice) * ((item.quantity)
          ? item.quantity : 1)) + subTotal
        : subTotal;
    });
  }
  return subTotal > 0 ? subTotal : 0;
};

export function getCustomizationFromOptionId(customizations, optionId) {
  return customizations.find(customization => customization.options.map(option => option.id).includes(optionId));
}

export const getNewestOrder = (state) => {
  if (state.length <= 1) {
    return state[0];
  }
  return state.reduce((prev, curr) => (prev.createdAt > curr.createdAt ? prev : curr));
};

// Handles adding a product to cart and recent orders to cart
export const addToCart = async (
  actions,
  user,
  currentOrder,
  orderItems,
  orderId,
  history,
  isReorder,
  reOrderId,
  goToMenuPage = true,
) => {
  let newestOrderId;
  let promises = [];
  // Check if there is an existing order and if the user is logged in
  const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
  if (isReorder && reOrderId) mutableOrder.reOrderId = reOrderId;
  if (!orderId && !user) {
    actions.createOrder(user, mutableOrder);
    promises = orderItems.map(async (orderItem) => {
      const createOrderItemResult = await actions.createOrderItem(user, orderItem, orderId);
      return createOrderItemResult;
    });
  } else if (!orderId) {
    // If user is signed in but has no existing order.
    const response = await actions.createOrder(user, mutableOrder);
    if (response.error) {
      return;
    }
    newestOrderId = response.id;
    // If user is signed in and has an existing order.
    promises = orderItems.map(async (orderItem) => {
      let item = cloneDeep(orderItem);
      if (isReorder) {
        // If reordering item, destructure into new item minus orderItemId
        const { id, ...partialOrderItem } = orderItem;
        item = partialOrderItem;
      }
      const createOrderItemResult = await actions.createOrderItem(user, item, response.id);
      return createOrderItemResult;
    });
  } else {
    // If user is signed in and has an existing order.
    promises = orderItems.map(async (orderItem) => {
      let item = cloneDeep(orderItem);
      if (isReorder) {
        // If reordering item, destructure into new item minus orderItemId
        const { id, ...partialOrderItem } = orderItem;
        item = partialOrderItem;
      }
      const createOrderItemResult = await actions.createOrderItem(user, item, orderId);
      return createOrderItemResult;
    });
    newestOrderId = orderId;
  }

  try {
    await Promise.all(promises);
  } catch (error) {
    console.log('Error while adding items to cart', error);
  }

  if (goToMenuPage) history.push(Routes.path.menuPage);

  // eslint-disable-next-line consistent-return
  return newestOrderId;
};

/**
 * Resource calls for lifecycle events
 * in RecentOrdersPage
 * @param actions the actions prop
 * @param user the current logged in user
 * @param products the product catalogue
 * @param users the users resource
 * @param loading the loading state
 */
export async function getComponentResources(actions, user, products, users, loading) {
  if (user && !loading) {
    try {
      // Fetch the user's orders
      await actions.getAllResources(user.token, ['users', user.id, 'orders']);
    } catch (error) {
      console.log('There is an API error', error);
    }
  } else if (isEmpty(users)) {
    try {
      await actions.getAllResources(user.token, ['users', user.id]);
    } catch (error) {
      console.log('There is an API error', error);
    }
  }
  if (products && products.length === 0) {
    try {
      // Fetch the products for onAddToCart function
      await actions.getAllResources(user.token, ['products']);
    } catch (error) {
      console.log('There is an API error', error);
    }
  }
}

/**
 * Render text for renderDeliveryAddress and renderPickupAddress
 * @param {*} text the text to be rendered
 * @param {*} style the style to apply to the text
 */
const renderText = (text, style) => (
  <Typography className={style}>
    {text}
  </Typography>
);

/**
 * Render the pickup address for the exported renderAddress function
 * @param {*} location the location object
 * @param {*} classes the classes prop
 * @param {*} translation the translation prop
 */
const renderPickupAddress = (location, classes, translation, deliveryOption) => {
  if (!location) return <div />;
  const { name, phone } = location;

  const addressSplit = location.address
    ? location.address.split(', ')
    : [];
  const street = addressSplit[0];
  const city = addressSplit[1];
  const province = addressSplit[2];
  const postalCode = addressSplit[3] || '';
  const dash = postalCode ? ' - ' : '';
  const isDineIn = deliveryOption === DINE_IN;

  return (
    <div>
      {renderText(isDineIn
        ? `${translation(DINE_IN)}`
        : `${translation(PICKUP)}`, classes.largerBoldText)
      }
      {renderText(name, classes.boldText)}
      {renderText(street, classes.boldText)}
      {renderText(`${city}, ${province}${dash}${postalCode}`, classes.boldText)}
      {renderText(phone, classes.boldText)}
    </div>
  );
};

/**
 * Render the delivery address for the exported renderAddress function
 * @param {*} address the address object
 * @param {*} classes the classes prop
 * @param {*} translation the translation prop
 */
const renderDeliveryAddress = (address, classes, translation, deliveryOption) => {
  const {
    nickname, unitNumber, streetAddress, city, buzzerNumber,
  } = address;

  return (
    <div>
      {renderText(`${translation(deliveryOption)}`, classes.largerBoldText)}
      {nickname && renderText(`Nickname: ${nickname}`, classes.boldText)}
      {buzzerNumber && renderText(`Buzzer: ${buzzerNumber}`, classes.boldText)}
      {
        unitNumber
          ? renderText(`${unitNumber} - ${streetAddress}`, classes.boldText)
          : renderText(`${streetAddress}`, classes.boldText)
      }
      {renderText(`${city}`, classes.boldText)}
    </div>
  );
};

/**
 * Render the correct address according to deliveryOption
 * @param {*} order the currentOrder
 * @param {*} deliveryOption PICKUP or DELIVERY
 * @param {*} classes the classes prop
 * @param {*} translation the translation prop
 */
export const renderAddress = (order, deliveryOption, classes, translation) => {
  if (deliveryOption === PICKUP || deliveryOption === DINE_IN) {
    return renderPickupAddress(order.location, classes, translation, deliveryOption);
  }
  return order.address && renderDeliveryAddress(order.address, classes, translation, order.deliveryOption);
};

export const createTableBody = (
  tableHeader,
  orderItems,
  getRowData,
  generatePriceAndTaxSummary,
) => {
  const header = { id: 'headers', headers: tableHeader };

  if (orderItems && orderItems.length === 0) {
    return [header];
  }

  const tableBody = orderItems && orderItems.map((orderItem) => {
    const { id } = orderItem;

    const rowData = getRowData(tableHeader, orderItem);

    return {
      ...rowData,
      id,
    };
  });
  const table = [header, ...tableBody];

  if (generatePriceAndTaxSummary) {
    // Need a deep copy here because in ItemTableCellComponent we need to
    // perform a sort, which alters the table body's structure
    const mutableTableBody = Immutable.asMutable(table, { deep: true });
    const summary = generatePriceAndTaxSummary();
    const tableBodyWithPriceAndTaxSummary = mutableTableBody.concat(summary);
    return tableBodyWithPriceAndTaxSummary;
  }

  return table;
};

/**
 * Set section property for subItem option according to subItem portion name
 * when editing item from cart
 * @param {*} presetSubItem
 * @param {*} optionIds
 */
export const setSubOptionSection = (presetSubItem, optionIds) => {
  if (!presetSubItem || !presetSubItem.options || !optionIds) return [];
  const subItem = cloneDeep(presetSubItem);
  presetSubItem.options.forEach((option, i) => {
    if (optionIds.includes(option.id)) {
      subItem.options[i].section = subItem.portion.name;
    }
  });
  return subItem.options;
};

/**
   * Return an array of included customizations and the associated included
   * options for a given product item
   * @param{*} the product item
   */
export const getIncludedCustomizations = (productItem) => {
  const includedProductOptions = [];
  if (!productItem) return includedProductOptions;
  const { customizations = [], includedOptions = [] } = productItem;
  // includedOptionIds = all ids across all customizations for that productItem
  const includedOptionsIds = includedOptions.map(includedOption => includedOption.id);
  customizations.forEach((cust) => {
    const customizationOptions = cust.options;
    const options = [];
    customizationOptions.forEach((option) => {
      if (includedOptionsIds.includes(option.id)) options.push(option);
    });
    // By default, we want to select the first option for each mandatoryCustomization.
    // but ONLY if the corresponding customization doesn't have an included option from that group already.
    if (cust.maxOptions === 1 && cust.minOptions === 1 && cust.isSingleSelectDefault) {
      // mandatoryCustomizationOptionIds = all ids for this isSingleSelectDefault customization
      const mandatoryCustomizationOptionsIds = cust.options.map(opt => opt.id);
      // Get mandatoryCustomizationOptionsIds already in includedOptionIds
      const includedCustOpts = []; // included options for that specific customization
      mandatoryCustomizationOptionsIds.forEach((opt) => {
        if (includedOptionsIds.includes(opt.id)) includedCustOpts.push(opt.id);
      });
      // If no mandatoryCustomizationOptionsIds are already in includedOptionIds, push the first one
      if (includedCustOpts.length === 0) {
        options.push(cust.options[0]);
      }
    }
    if (options.length > 0) includedProductOptions.push({ id: parseInt(cust.id, 10), options });
  });

  return includedProductOptions;
};

export const sortCustomizations = (customizations, param) => {
  const mutableObj = Immutable.asMutable(customizations, { deep: true });
  return mutableObj.slice().sort(compareValues(param, 'asc'));
};

export const filterUniqueIds = (allOptions) => {
  // To get unique options, we will filter out duplicates using indexOf and lastIndexOf
  const optionIds = allOptions.map(option => option.id.toString());
  const uniqueOptions = allOptions.filter(obj => (optionIds.indexOf(obj.id) === optionIds.lastIndexOf(obj.id)));
  return uniqueOptions;
};

export const getIsDefaultOptions = (item) => {
  const customizations = get(item, 'customizations');
  if (!customizations) return [];
  const allOptions = flattenDeep(customizations.map(customization => customization.options));
  const uniqueOptions = filterUniqueIds(allOptions);
  // Then, filter options with isDefault equaling true
  return uniqueOptions.filter(option => option.isDefault === true);
};

export const getDefaultSelectedOptions = (item) => {
  let singleSelectCustomizations = [];
  if (item.customizations) {
    const filteredCustomizations = item.customizations.filter(customization => customization.maxOptions === 1 && customization.minOptions === 1);
    singleSelectCustomizations = sortCustomizations(filteredCustomizations, 'position');
  }

  let result = item.includedOptions;

  if (singleSelectCustomizations.length === 0) return result;

  // Filter single select customizations that need to have a default value set initially.
  const singleSelectDefaultCustomizations = singleSelectCustomizations.filter(customization => customization.isSingleSelectDefault);

  if (singleSelectDefaultCustomizations.length === 0) return result;

  // By default, we want to select the first option for each mandatoryCustomization.
  // but ONLY if the corresponding customization doesn't have an included option from that group already.

  // First we filter all customization with no options appearing in includedOption.
  let notIncludedCustomizations = [];
  singleSelectDefaultCustomizations.forEach((customization) => {
    const mandatoryCustomizationOptionsIds = customization.options.map(opt => opt.id);
    let shouldAddToNotIncludedCustomizations = true;
    item.includedOptions.forEach((opt) => {
      if (mandatoryCustomizationOptionsIds.includes(opt.id)) shouldAddToNotIncludedCustomizations = false;
    });
    if (shouldAddToNotIncludedCustomizations) notIncludedCustomizations = notIncludedCustomizations.concat(customization);
  });
  // In some case the customizations we filtered in notIncludedCustomizations don't have any of their options
  // included, so we can now select the first options from each.
  const firstMandatoryOptions = notIncludedCustomizations.map(customization => customization.options[0]);
  if (firstMandatoryOptions.length > 0) result = result.concat(firstMandatoryOptions);
  return result;
};

// https://stackoverflow.com/a/45239354/11542028
export function addMinutes(timeString, addMinutes) {
  if (!addMinutes) return timeString;
  if (!timeString.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
    return null;
  }
  const timeSplit = timeString.split(':');
  let hours = parseInt(timeSplit[0]);
  let minutes = parseInt(timeSplit[1]) + parseInt(addMinutes);
  hours += Math.floor(minutes / 60);
  while (hours >= 24) {
    hours -= 24;
  }
  minutes %= 60;
  return `${('0'.concat(hours)).slice(-2)}:${('0'.concat(minutes)).slice(-2)}`;
}

export const evaluateTimeLimit = (timeLimit, currentOrder) => {
  const orderTime = currentOrder.isASAP ? new Date() : new Date(currentOrder.desiredTime * 1000);
  const orderTimeStamp = currentOrder.isASAP ? (Date.parse(new Date()) / 1000) : (currentOrder.desiredTime);
  const dayOfOrder = GETDAY_FULLDAYS[orderTime.getDay()];
  // Check if day of order is within this time limit's days or dates
  if (!timeLimit.daysOfWeek.includes(dayOfOrder) && timeLimit.endDate == null) {
    return false;
  } else if (orderTimeStamp < get(timeLimit, 'startDate', 0)) {
    return false;
  } else if (timeLimit.endDate != null && orderTimeStamp > get(timeLimit, 'endDate', 0)) {
    return false;
  }

  // isBefore and isAfter will indicate if orderTime is before or after the timeLimit;
  // TODO: Add validations for days of month.
  let isBefore = false;
  let isAfter = false;

  if (timeLimit.start && timeLimit.end) {
    // Get hours and minutes from the time limit's start.
    const timeLimitStartHours = parseInt(timeLimit.start.split(':')[0], 10);
    const timeLimitStartMinutes = parseInt(timeLimit.start.split(':')[1], 10);
    // Get hours and minutes from the time limit's end.
    const timeLimitEndHours = parseInt(timeLimit.end.split(':')[0], 10);
    const timeLimitEndMinutes = parseInt(timeLimit.end.split(':')[1], 10);
    const orderTimeHours = orderTime.getHours();
    const orderTimeMinutes = orderTime.getMinutes();
    // Now we evaluate if the orderTime is within the timeLimit interval.
    // TODO: Validate that start and end hours are valid strings ([hours]:[minutes])
    if (orderTimeHours < timeLimitStartHours) isBefore = true;
    if (orderTimeHours === timeLimitStartHours && orderTimeMinutes < timeLimitStartMinutes) isBefore = true;
    if (orderTimeHours > timeLimitEndHours) isAfter = true;
    if (orderTimeHours === timeLimitEndHours && orderTimeMinutes >= timeLimitEndMinutes) isAfter = true;
  }
  // If the orderTime is neither before or after, it's within the time limit !
  if (!isBefore && !isAfter) return true;
  return false;
};

export const addMinsToTimestamp = (mins, timestamp) => timestamp + (60 * mins);

// Get the category, given a productItem.
export const getCategoryFromProductItem = (categories, orderItem) => {
  if (!categories || !orderItem || !orderItem.productItem) return null;
  const { productItem } = orderItem;
  if (!productItem) return null;
  return categories.find(cat => cat.id === productItem.categoryId);
};

export const groupByCategory = (categories, items) => {
  const result = {};
  if (!categories || !items) return result;
  categories.forEach((category) => { result[category.id] = []; });
  items.forEach((item) => {
    const itemCategory = getCategoryFromProductItem(categories, item);
    if (itemCategory) result[itemCategory.id].push(item);
  });
  // Delete empty categories since they're not needed anymore.
  Object.keys(result).forEach((key) => { if (result[key].length === 0) delete result[key]; });
  return result;
};

/**
 * Check that the order has all the basic information,
 * such as order choice, location/address and desiredTime/ASAP
 * @param {*} order
 */
export const checkBasicOrderInfo = (order) => {
  if (!order) return false;
  // First, check that the order has a deliveryOption set.
  if (!order.deliveryOption) return false;
  // Check that the order has an address, or location
  if (!order.location && !order.address) return false;
  // Check that the order is for ASAP or has a desiredTime
  if (!order.isASAP && !order.desiredTime) return false;
  return true;
};

  // check if order is set within a holiday and return said hour in that case.
export const getHolidayHour = (dateObj, holidayHours) => {
  const currentHolidayHours = [];
  holidayHours.forEach((holidayHour) => {
    const holidayDate = formatEpoch(holidayHour.date);
    // We'll use the holiday hour with the same date as the order's
    if (DateHelper.sameDay(holidayDate, dateObj)) {
      currentHolidayHours.push(holidayHour);
    }
  });

  return currentHolidayHours;
};

export const getDayWorkingHours = (dateObj, hours = [], holidayHours = []) => {
  const dayIndex = dateObj.getDay();
  if (isEmpty(holidayHours)) return hours.filter(hour => hour.day === WEEKDAYARRAY[dayIndex]);
  const todayHolidayHours = getHolidayHour(dateObj, holidayHours);
  if (!isEmpty(todayHolidayHours)) return todayHolidayHours;
  return hours.filter(hour => hour.day === WEEKDAYARRAY[dayIndex]);
};

/**
 * DEPRECATED, use getTimeWindows pls
 * Get valid open/close times for the current selected day
 */
export const getTimeWindow = (deliveryOption, order) => {
  const selectedDate = order.desiredTime
    ? formatEpoch(order.desiredTime)
    : new Date();

  if (!order.location) return null;
  const { hours, holidayHours } = order.location;
  // Calculate day of week index (0 - 6)
  // Get delivery opening & closing times
  // Return closed hours if we don't have hours information

  const todayWorkingHour = getDayWorkingHours(selectedDate, hours, holidayHours);
  if (!todayWorkingHour) return null;


  if ([DELIVERY, CATERING].includes(order.deliveryOption) && todayWorkingHour.deliveriesClosed) {
    return null;
  }

  if (deliveryOption === PICKUP && todayWorkingHour.pickupsClosed) {
    return null;
  }
  const { deliveryOpen, deliveryClose } = todayWorkingHour;

  // Get location opening & closing times
  const { open, close } = todayWorkingHour;
  if (deliveryOption && [DELIVERY, CATERING].includes(order.deliveryOption)) return { open: deliveryOpen, close: deliveryClose };

  return { open, close };
};

export const getPrepTime = (order) => {
  if (!order.location) return 0;
  const prepTime = order.deliveryOption === PICKUP
    ? get(order, 'location.pickupTime', 0)
    : get(order, 'location.deliveryTime', 0);
  return prepTime || 0;
};

export const getOrderTimeZone = (order) => {
  return get(order, 'location.timezone', 'America/Vancouver');
};

export const validateTimeWindow = (order, selectedTime, timeWindow, addPrepTime = false) => {
  const orderTimeZone = getOrderTimeZone(order);
  const prepTime = getPrepTime(order);
  if (!timeWindow) return false;
  if (timeWindow.isOpen24Hours) return true;

  let afterMidnightOrder = false;
  const [todayOpenTime] = timeWindow.open.split(':');
  const [todayCloseTime] = timeWindow.close.split(':');

  const opensBeforeFiveAM = todayOpenTime >= 0 && todayOpenTime < 5 && todayCloseTime <= 24;
  const afterMidnightClose = (timeWindow.close >= '00:00' && timeWindow.close <= '05:00' && !opensBeforeFiveAM);

  const desiredHours = getDesiredTimeFromEpoch(selectedTime, orderTimeZone);
  const valueWithPrepTime = (order.isASAP || addPrepTime)
    ? addMinutes(desiredHours, prepTime)
    : addMinutes(desiredHours, 0);

  // Orders that come after midnight (After 00:00 and before 05:00) require a different logic
  // to evaluate if they are within location hours. This is a specific block that looks at
  // locations that are open past midnight but we should not enter this block for locations with
  // very early hours - for example, an airport
  if (valueWithPrepTime >= '00:00' && valueWithPrepTime <= '05:00' && !opensBeforeFiveAM) {
    afterMidnightOrder = true;
  }

  // Return true if time selected is within open hours
  const mutableTimeWindow = Immutable.asMutable(timeWindow, { deep: true });
  if (afterMidnightOrder) return timeWindow.close < '05:00' && valueWithPrepTime < timeWindow.close;
  if (afterMidnightClose) mutableTimeWindow.close = '24:00';
  return (valueWithPrepTime >= timeWindow.open && valueWithPrepTime <= mutableTimeWindow.close);
};

export const locationIsOpen24HoursForDeliveryOption = (todayWorkingHour, deliveryOption) => {
  const {
    deliveryOpenAllDay, businessOpenAllDay, pickupOpenAllDay, pickupClose, pickupOpen, pickupsClosed,
  } = todayWorkingHour;
  if ([DELIVERY, CATERING].includes(deliveryOption) && deliveryOpenAllDay) return true;
  if ([DINE_IN, GROUP].includes(deliveryOption) && businessOpenAllDay) return true;
  // Any PICKUP hours settings will override Business hours, otherwise default to Business hours
  if ([PICKUP].includes(deliveryOption) && (pickupOpenAllDay
    || pickupOpenAllDay === null && pickupClose === null && pickupOpen === null && pickupsClosed === null && businessOpenAllDay)) {
    return true;
  }
  return false;
};

/**
 * Get valid open/close times for the current selected day
 */
export const getTimeWindows = (deliveryOption, order) => {
  const selectedDate = order.desiredTime
    ? formatEpoch(order.desiredTime)
    : new Date();

  if (!order.location) return null;
  const { hours, holidayHours } = order.location;

  // Get delivery opening & closing times
  // Return closed hours if we don't have hours information

  const todayWorkingHours = getDayWorkingHours(selectedDate, hours, holidayHours);
  if (isEmpty(todayWorkingHours)) return null;
  return todayWorkingHours.map((todayWorkingHour) => {
    if ([DELIVERY, CATERING].includes(order.deliveryOption) && todayWorkingHour.deliveriesClosed) {
      return null;
    }

    if (deliveryOption === PICKUP && todayWorkingHour.pickupsClosed) {
      return null;
    }

    if (locationIsOpen24HoursForDeliveryOption(todayWorkingHour, order.deliveryOption)) return { isOpen24Hours: true };

    // Get location opening & closing times
    if (deliveryOption && [DELIVERY, CATERING].includes(order.deliveryOption)) {
      const { deliveryOpen, deliveryClose } = todayWorkingHour;
      return { open: deliveryOpen, close: deliveryClose };
    }
    const { open, close } = todayWorkingHour;
    return { open, close };
  });
};

/**
 * Validate the selected time is within the location's time window
 * @param {*} selectedTime timestamp
 */
export const validateSelectedTime = (order, selectedTime, addPrepTime, isASAP = false) => {
  // Exit early if selectedTime is for the past
  if (!isASAP && selectedTime < Date.now()) return false;
  const timeWindows = getTimeWindows(order.deliveryOption, { ...order, desiredTime: selectedTime });
  // Validate all time windows for the day and return if one of them works.
  if (isEmpty(timeWindows)) return false;
  const timeWindowValidations = timeWindows.map(timeWindow => validateTimeWindow(order, selectedTime, timeWindow, addPrepTime));
  return timeWindowValidations.some(validation => !!validation);
};

export const availableDeliveryOptions = () => {
  const result = [];
  if (FeatureFlags.enableDelivery) result.push(DELIVERY);
  if (FeatureFlags.enablePickup) result.push(PICKUP);
  if (FeatureFlags.enableDineIn) result.push(DINE_IN);
  if (FeatureFlags.enableCatering) result.push(CATERING);
  return result;
};

export const renderCityAndProvinceText = (address) => {
  // Split and return the text...
  const addressSplit = address
    ? address.split(', ')
    : [];
  const city = addressSplit[1];
  const postalCode = addressSplit[3];
  return `${city || ''}, ${postalCode || ''}`;
};

/**
 * Function to replace the year in a copyright string with current year
 * @param {*} copyrightString a string like 'Copyright \u00A9 2018 Craver Demo'
 */
export const addCurrentCopyrightYear = (copyrightString) => {
  const now = new Date();
  return copyrightString.replace(/\d{4}/, now.getFullYear());
};

export const getSelectedLocationOrAddressId = (order) => {
  if ([DELIVERY, CATERING].includes(order)) {
    if (!order.address) return null;
    return order.address.id;
  }
  if (!order.location) return null;
  return order.location.id;
};

export const getMobileFriendlyStyle = (styleProps, classKey, nameOnly = false) => {
  const { classes, width } = styleProps;
  const key = isDesktopMode(width) ? classKey : `${classKey}Mobile`;
  if (nameOnly) return key;
  return classes[key];
};

export const filterByLocation = (resources, currentOrder) => {
  if (!FeatureFlags.MenuPage.filterProductsByLocation || !resources || !currentOrder
    || !currentOrder.location || !currentOrder.location.id) {
    return resources;
  }
  return resources.filter((res) => {
    if (!res.locations || res.locations.length < 1) {
      return true;
    }
    return res.locations.includes(currentOrder.location.id);
  });
};

export const filterByDeliveryOption = (resources, currentOrder) =>
  resources.filter((res) => {
    if (currentOrder && currentOrder.deliveryOption) {
      return res.orderTypes.length === 0 || res.orderTypes.includes(currentOrder.deliveryOption);
    }
    return true;
  });

export const toTimestamp = (strDate) => {
  const datum = Date.parse(strDate);
  return datum / 1000;
};

export const hhmmssToMinutes = (hhmmss) => {
  const times = hhmmss.split(':');
  let mins = parseInt(times[1], 10);
  mins += parseInt((times[0] * 60), 10); // convert hrs to mins
  // ignoring secs because prepTime is probably in mins and hrs
  return mins;
};

export const hhmmssToMilliseconds = hhmmss => (
  timestampToMs(hhmmssToMinutes(hhmmss) * 60)
);

const checkValidDayOfWeek = (validDays = [], orderTime) => (
  validDays.length === 0
  || validDays.includes(WEEKDAYARRAY[orderTime.getDay()])
);

const checkValidMonth = (validMonths = [], orderTime) => (
  validMonths.length === 0
  || validMonths.includes(invertMonthDict[orderTime.getMonth() - 1])
);

const checkValidDayOfMonth = (validMonthDays = [], orderTime) => (
  validMonthDays.length === 0
  || validMonthDays.includes(orderTime.getDate())
);
const checkStartTime = (startTime, orderTime) => {
  const orderTimeAsString = `${orderTime.getHours()}:${orderTime.getMinutes()}`;
  return (
    !startTime
    || hhmmssToMilliseconds(startTime) <= hhmmssToMilliseconds(orderTimeAsString)
  );
};

const checkEndTime = (endTime, orderTime) => {
  const orderTimeAsString = `${orderTime.getHours()}:${orderTime.getMinutes()}`;
  return (
    !endTime
    || hhmmssToMilliseconds(orderTimeAsString) <= hhmmssToMilliseconds(endTime)
  );
};

// Since both Products and Categories share the same structure, we can reuse this function for products
export const getAvailableCategories = (categories, currentOrder) => {
  let availableCategories = filterByLocation(categories, currentOrder);
  availableCategories = filterByDeliveryOption(availableCategories, currentOrder);

  if (FeatureFlags.MenuPage.hideTimeRestrictedCategories) {
    const desiredTime = currentOrder && currentOrder.desiredTime;
    const orderTime = desiredTime
      ? new Date(timestampToMs(desiredTime))
      : new Date();

    const timeRestrictedCategories = availableCategories.filter((cat) => {
      const { timeLimits = [] } = cat;
      let validDateTime = true;

      if (timeLimits.length > 0) {
        validDateTime = false;
        cat.timeLimits.forEach(({
          daysOfMonth, daysOfWeek, end, months, start,
        }) => {
          validDateTime = validDateTime || (
            checkValidMonth(months, orderTime)
            && checkValidDayOfMonth(daysOfMonth, orderTime)
            && checkValidDayOfWeek(daysOfWeek, orderTime)
            && checkStartTime(start, orderTime)
            && checkEndTime(end, orderTime)
          );
        });
      }
      if (validDateTime) {
        return cat;
      }
    });
    return timeRestrictedCategories;
  }
  return availableCategories;
};

export const getAvailableProducts = (products, currentOrder) =>
  filterByLocation(products, currentOrder);

/**
 *
 * @param {props} props should contain 'classes', 'currentOrder', and 'translation'
 * @param {props.classes} classes should contain 'infoText' class
 * @param {props.currentOrder} currentOrder should contain 'isAsap' boolean
 */
export const renderDesiredTime = (props) => {
  // eslint-disable-next-line react/prop-types
  const { currentOrder, translation } = props;
  const { isASAP } = currentOrder;

  if (isASAP) return <Typography className="orderInfoBanner-infoText">{translation('ASAP')}</Typography>;

  const desiredDate = formatEpoch(currentOrder.desiredTime);

  return (
    <Typography className="orderInfoBanner-infoText">
      {desiredDate.toLocaleString()}
    </Typography>
  );
};

export const getOutOfStockItems = (locationId, currentOrder, products) => {
  const outOfStockItems = [];
  if (currentOrder && currentOrder.items) {
    currentOrder.items.forEach((item) => {
      const product = getProductFamily(products, item.productItem.id);
      if (product.outOfStockLocations.includes(locationId)
        || product.items.find(pItem => pItem.outOfStockLocations.includes(locationId))) {
        outOfStockItems.push(item);
      } else if (!product.locations.includes(locationId)) {
        outOfStockItems.push(item);
      }
    });
  }
  return outOfStockItems;
};

const capitalizeOnlyFirstLetterOfOneWord = (word) => {
  const lowerCased = word.toLowerCase();
  const capitalized = lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
  return capitalized;
};

const getHideASAPFeatureFlag = (deliveryOption) => {
  const onlyFirstLetterCapitalized = capitalizeOnlyFirstLetterOfOneWord(deliveryOption);
  return [PICKUP, DELIVERY].includes(deliveryOption)
    ? FeatureFlags.timePickerFlags[`hide${onlyFirstLetterCapitalized}ASAPIfOutside${onlyFirstLetterCapitalized}Hours`]
    : false;
};

export const shouldShowTimePickerButton = (currentOrder, type) => {
  const { deliveryOption } = currentOrder;
  const { timePickerFlags } = FeatureFlags;

  switch (type) {
    // ASAP is a special case, besides feature flags we need to check if there's enough time to place an ASAP order.
    case 'ASAP': {
      const isEnoughTimeForASAP = validateSelectedTime(currentOrder, Date.now(), true, true);
      const hideASAP = !!getHideASAPFeatureFlag(deliveryOption) && !isEnoughTimeForASAP;
      return (deliveryOption === PICKUP && timePickerFlags.pickupASAP && !hideASAP)
        || (deliveryOption === DELIVERY && timePickerFlags.deliveryASAP && !hideASAP)
        || (deliveryOption === CATERING && timePickerFlags.cateringASAP)
        || (deliveryOption === DINE_IN && timePickerFlags.dineInASAP);
    }
    case 'DatePicker':
      return (deliveryOption === PICKUP && timePickerFlags.pickupDatepicker)
        || (deliveryOption === DELIVERY && timePickerFlags.deliveryDatepicker)
        || (deliveryOption === CATERING && timePickerFlags.cateringDatepicker)
        || (deliveryOption === DINE_IN && timePickerFlags.dineInDatepicker);
    default:
      return false;
  }
};

export const getResponsiveStyles = (initStyles, theme, jsonToMerge) => {
  const jsonStyleKeys = Object.keys(jsonToMerge);
  jsonStyleKeys.forEach((key) => {
    const jsonStyle = jsonToMerge[key];

    // This assignment to a function parameter imitates the current way responsive styles are set
    initStyles[key] = {
      ...initStyles[key],
      [theme.breakpoints.down('sm')]: jsonStyle,
    };
  });
  return initStyles;
};

export const isDollarReward = coupon => coupon && coupon.rewardTiers && coupon.actionType === 'DOLLAR';

export function getTableNumbers(orderLocation) {
  const location = orderLocation;
  const tableNumberListString = get(location, 'tableNumbers', '');
  return tableNumberListString ? tableNumberListString.split(',') : [];
}

export const isTableNumberInLocation = (tableNumber, location) => {
  if (!tableNumber || !location || !location.tableNumbers) return false;
  const locationTableNumbers = getTableNumbers(location);
  return locationTableNumbers.includes(tableNumber);
};

export function shouldShowTableNumber(currentOrder) {
  return currentOrder.deliveryOption === DINE_IN && currentOrder.tableNumber && getTableNumbers(currentOrder.location).length > 0;
}

export const getLocationById = (locations, id) => (Array.isArray(locations)
  ? locations.find(location => `${location.id}` === `${id}`)
  : null);

export const getMaxDate = () => {
  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const { maxDateOffset } = FeatureFlags.ResourceConstants;
  return (new Date(+new Date() + maxDateOffset));
};

export const getFirstTableNumber = (location) => {
  const tableNumbers = get(location, 'tableNumbers');
  if (!tableNumbers) return '';
  return tableNumbers.split(',')[0];
};

/**
 * Turn names like 'Regular', 'Small', 'Test', or 'Large' into better names
 */
export const getDescriptiveItemName = (itemId, itemIdToWithProductNameMap, itemName) => {
  const descriptiveName = get(itemIdToWithProductNameMap, itemId, '');
  return itemName.length <= 7 ? descriptiveName : itemName;
};

export const getRedeemedItems = orderItems => orderItems.filter(({ redeemedPoints }) => redeemedPoints);

export const getRedeemedAmount = (order) => {
  const orderItems = get(order, 'items', []);
  const redeemedOrderItemPrices = getRedeemedItems(orderItems).map(({ totalRedeemedAmount }) => totalRedeemedAmount);
  return redeemedOrderItemPrices.reduce((accumulator, item) => accumulator + item, 0);
};

export const getItemTotal = order => get(order, 'items', []).reduce((accumulator, item) => accumulator + (get(item, 'price', 0) * item.quantity), 0);

export const isCouponRedeemed = (couponToCheck, order) => {
  const orderCoupons = get(order, 'coupons', null);
  if (!orderCoupons) return false;
  for (let i = 0; i < orderCoupons.length; i++) {
    if (orderCoupons[i].id === couponToCheck.id) {
      return true;
    }
  }
  return false;
};

export const calculateTipAmount = (tipPercentage, order) => {
  const { price, totalPrice, tipAmount, calculatedServiceFees, tax } = order;
  let calculatedTip;
  if (price === totalPrice) calculatedTip = ((price) * tipPercentage) / 100;
  else calculatedTip = ((totalPrice - tipAmount - calculatedServiceFees - tax) * tipPercentage) / 100;
  return calculatedTip;
};

export const calculateTipObjectValue = (order, tipOptionValue) => {
  const { price, totalPrice, tipAmount, calculatedServiceFees, tax } = order;
  if (price === totalPrice) {
    return tipOptionValue;
  }
  return parseInt((tipAmount / (totalPrice - tipAmount - calculatedServiceFees - tax)) * 100, 10);
};

export const getTipAmount = (tipOptionId, order, useSmartTip = false) => {
  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const { tipOptions, smartTipOptions } = FeatureFlags.ResourceConstants;
  return (useSmartTip
    ? smartTipOptions.find(({ id }) => id === tipOptionId).value
    : calculateTipAmount(tipOptions.find(({ id }) => id === tipOptionId).value, order)
  );
};

export const getSelectedTipOptionId = (order, useSmartTip = false) => {
  if (!order.tipAmount) return 0;
  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const { tipOptions, smartTipOptions } = FeatureFlags.ResourceConstants;
  const tipOption = useSmartTip
    ? smartTipOptions.find(({ value }) => value === order.tipAmount)
    : tipOptions.find(({ value }) => value === calculateTipObjectValue(order, value));
  return tipOption ? tipOption.id : 'customize';
};

export const formatDesiredDateTimeString = (datetime) => {
  const date = formatDesiredDate(datetime);
  const time = formatDesiredTime(datetime);
  return `${date} - ${time}`;
};

export const customDecode = str => str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

export const getCurrencySymbol = () => {
  // eslint-disable-next-line no-undef
  if (window.companyCurrencySymbol) return customDecode(window.companyCurrencySymbol);
  return '$';
};

export const getFormattedPrice = (price = 0) => (price !== null) && `${getCurrencySymbol()}${price.toFixed(2)}`;

export const isArraySequentialByX = (array, x) => {
  if (array) {
    for (let i = 0; i < array.length - 1; i += 1) {
      if (array[i + 1] - array[i] !== x) {
        return false;
      }
    }
  }
  return true;
};

export const dayIndexToShortform = (x) => {
  switch (x) {
    case 0:
      return 'Mon';
    case 1:
      return 'Tue';
    case 2:
      return 'Wed';
    case 3:
      return 'Thu';
    case 4:
      return 'Fri';
    case 5:
      return 'Sat';
    case 6:
      return 'Sun';
    default:
      return '-1';
  }
};

export const convertDayToIndex = (day) => {
  switch (day) {
    case 'MONDAY':
      return 0;
    case 'TUESDAY':
      return 1;
    case 'WEDNESDAY':
      return 2;
    case 'THURSDAY':
      return 3;
    case 'FRIDAY':
      return 4;
    case 'SATURDAY':
      return 5;
    case 'SUNDAY':
      return 6;
    default:
      return -1;
  }
};

export const renderTypography = (text, key, props, subTextStyle, separator = '') => {
  // eslint-disable-next-line react/prop-types
  const { textStyle } = props;
  return (
    <Typography key={key} className={textStyle || subTextStyle}>
      {`\u00A0${text}${separator}`}
    </Typography>
  );
};

export const parseWindow = (type, days, time, index, props, subTextStyle = {}, separator = '') => {
  const { translation } = props;
  const isDeliveryOrPickup = type === DELIVERY || type === PICKUP;
  const isClosedMessage = time === translation('HoursComponent.closed');
  let hours;
  let openingHour;
  let closingHour;
  let firstDay;
  let lastDay;

  if (!isClosedMessage && time) {
    hours = time.split(',');
    openingHour = getTime(hours[0]);
    closingHour = getTime(hours[1]);
    // If both openingHour and closingHour are AM/PM, remove the first suffix
    if (hours[0] !== 'null' && openingHour.split(' ')[1] === closingHour.split(' ')[1]) {
      openingHour = openingHour.split(' ').slice(0, -1).join();
    }
  }

  if (isDeliveryOrPickup && (!openingHour || !closingHour) && !isClosedMessage) {
    return null;
  }

  if (days.length > 1) {
    firstDay = dayIndexToShortform(Math.min(...days));
    lastDay = dayIndexToShortform(Math.max(...days));

    if (isClosedMessage || openingHour === closingHour) {
      return renderTypography(`${firstDay} - ${lastDay}: ${translation('HoursComponent.closed')}`, index, props, subTextStyle, separator);
    }
    return renderTypography(`${firstDay} - ${lastDay}: ${openingHour} - ${closingHour}`, index, props, subTextStyle, separator);
  }
  firstDay = dayIndexToShortform(days[0]);
  lastDay = dayIndexToShortform(days[0]);

  if (isClosedMessage || openingHour === closingHour) {
    return renderTypography(`${firstDay}: ${translation('HoursComponent.closed')}`, index, props, subTextStyle, separator);
  }
  return renderTypography(`${firstDay}: ${openingHour} - ${closingHour}`, index, props, subTextStyle, separator);
};

export const parseNonSequentialWindow = (type, days, time, index, props, subTextStyle = {}, separator = '') => {
  const { translation } = props;
  const isDeliveryOrPickup = type === DELIVERY || type === PICKUP;
  const isClosedMessage = time === translation('HoursComponent.closed');
  let hours;
  let openingHour;
  let closingHour;
  let theDays = days;

  if (!isClosedMessage && time) {
    hours = time.split(',');
    openingHour = getTime(hours[0]);
    closingHour = getTime(hours[1]);
    // If both openingHour and closingHour are AM/PM, remove the first suffix
    if (hours[0] !== 'null' && openingHour.split(' ')[1] === closingHour.split(' ')[1]) {
      openingHour = openingHour.split(' ').slice(0, -1).join();
    }
  }

  if (isDeliveryOrPickup && (!openingHour || !closingHour) && !isClosedMessage) {
    return null;
  }

  theDays = theDays.map((day) => {
    const d = dayIndexToShortform(day);
    return d;
  });

  if (isClosedMessage || openingHour === closingHour) {
    return renderTypography(`${theDays.toString()}: ${translation('HoursComponent.closed')}`, index, props, subTextStyle, separator);
  }
  return renderTypography(`${theDays.toString()}: ${openingHour} - ${closingHour}`, index, props, subTextStyle, separator);
};

export const getProductItemMaxPrepTime = (order) => {
  let maxPrepTime = 0;
  if (order && order.items && order.items.length > 0) {
    order.items.forEach((item) => {
      if (item.productItem.prepTime > maxPrepTime) {
        maxPrepTime = item.productItem.prepTime;
      }
    });
  }
  return maxPrepTime;
};

export const getCategoryPrepTime = (category, orderItems) => {
  if (category && category.quantityThreshold === 0) return category.prepTime;
  let totalQuantity = 0;
  orderItems.forEach((oi) => { totalQuantity += oi.quantity; });
  const quantityMathCeil = Math.ceil(totalQuantity / category.quantityThreshold);
  if (quantityMathCeil === 0) return category.prepTime;
  return quantityMathCeil * category.prepTime;
};

export const getCategoryMaxPrepTime = (order, categories) => {
  const maxPrepTime = 0;
  if (!order || !order.items || order.items.size === 0) return maxPrepTime;
  const categoryPrepTimes = [];
  // Group orderItems by Category
  const orderItemsByCategory = groupByCategory(categories, order.items);
  Object.keys(orderItemsByCategory).forEach((key) => {
    const category = categories.find(cat => cat.id === parseInt(key, 10));
    const categoryPrepTime = getCategoryPrepTime(category, orderItemsByCategory[key]);
    categoryPrepTimes.push(categoryPrepTime);
  });
  return Math.max(...categoryPrepTimes);
};

export const getMaxPrepTime = (order, categories) => {
  const productItemMaxItem = getProductItemMaxPrepTime(order);
  const categoryMaxPrepTime = getCategoryMaxPrepTime(order, categories);
  return Math.max(productItemMaxItem, categoryMaxPrepTime);
};

export const validateDesiredTime = (desiredTime, order, categories) => {
  const maxPrepTime = getMaxPrepTime(order, categories);
  const windowSize = order && order.isASAP ? 45 : maxPrepTime;

  const currentTime = new Date().getTime() / 1000.0;
  const validDesiredTime = addMinsToTimestamp(windowSize, currentTime);

  return desiredTime > validDesiredTime; // is desiredTime after validDesiredTime
};

export const getASAPTime = (order, categories) => {
  const maxPrepTime = getMaxPrepTime(order, categories);
  const orderLocation = order && order.location;
  if (!orderLocation) return undefined;

  const orderTime = [DELIVERY, CATERING].includes(order.deliveryOption)
    ? orderLocation.deliveryTime
    : orderLocation.pickupTime;

  if (orderTime === null || orderTime === undefined) {
    if (validateDesiredTime(Date.now() + (60000 * maxPrepTime)) / 1000) {
      return Math.round((Date.now() + (60000 * maxPrepTime)) / 1000);
    }
    return Math.round((Date.now() + (60000 * maxPrepTime)) / 1000);
  }

  return Math.round((Date.now() + (60000 * (orderTime + maxPrepTime))) / 1000);
};

export const isInMenuPage = (history) => {
  const pathName = get(history, 'location.pathname');
  return ['/', '/menu'].includes(pathName);
};

export const productIsOutOfStock = (product, currentOrderLocationId) => {
  if (product.items.length === 1 && product.items[0].outOfStockLocations) {
    return (product.outOfStockLocations.includes(currentOrderLocationId))
    || product.items[0].outOfStockLocations.includes(currentOrderLocationId);
  }
  return product.outOfStockLocations.includes(currentOrderLocationId);
};

export const isCurbsideEnabled = (locations = [], currentOrder) => {
  if (!currentOrder.location) return false;
  const currentLocation = locations.find(loc => loc.id === currentOrder.location.id);
  if (currentLocation && currentLocation.CURBSIDE_ENABLED) return true;
  return FeatureFlags.CheckoutDrawer.enableCurbsidePickup;
};


/**
 * If there were previously no items in the cart, set the tip amount using the default tip value
 * NOTE: This has to be done before going to checkout and on FIRST item being added to cart
 * because if user has selected a tip amount we need to persist this value (not the default) and there
 * is currently no way to tell if the user has previously selected a tip amount for their order items
 */
export const setDefaultTipAmount = (order) => {
  const {
    tipOptions, smartTipOptions, defaultTipIndex, smartTipThreshold,
  } = FeatureFlags.ResourceConstants;
  const { price } = order;
  const useSmartTip = FeatureFlags.CoreView.PaymentDrawer.enableSmartTip && (price <= smartTipThreshold);
  const defaultTipObj = useSmartTip ? smartTipOptions[defaultTipIndex] : tipOptions[defaultTipIndex];

  const mutableOrder = Immutable.asMutable(order, { deep: true });
  if (defaultTipObj.key === 'noTip' || defaultTipObj.key === 'customizeAmount') {
    mutableOrder.tipAmount = 0;
  } else {
    mutableOrder.tipAmount = useSmartTip ? defaultTipObj.value : price * (defaultTipObj.value / 100);
  }
  return mutableOrder;
};

export const getDistance = (lat1, lon1, lat2, lon2) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = (Math.sin(radlat1) * Math.sin(radlat2)) + (Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta));
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  return dist;
};

// Returns an object array for pay at door options
export const getPayAtDoorOptionsObject = (location, translation) => {
  const payAtDoorOptionsArray = get(location, 'payAtDoorOptions', []).split(',');
  const optionIcons = {
    CASH: 'account_balance_wallet',
    DEBIT: 'credit_card',
    CREDIT: 'credit_card',
  };
  const optionName = (option => `${capitalize(translation('PaymentMethodComponent.payAtDoor'))}: ${capitalize(translation(`PayAtDoorComponent.${option.toLowerCase()}`))}`);

  return payAtDoorOptionsArray.map(option => ({ id: option, name: optionName(option), icon: optionIcons[option] }));
};

/**
   * This is a simple checksum validation known as the Luhn (or Mod-10) Algorithm
   * It catches most simple user errors with accidental digit duplication or
   * transposition and prevents invalid numbers from being processed
   * @param {*} value the credit card number as string
   */
export const validateCreditCard = (value) => {
  // Only accept digits
  if (/[^0-9]$/.test(value)) return false;
  // Strip non-numeric
  const ccValue = value.replace(/\D/g, '');

  // Luhn Algorithm
  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;

  for (let n = ccValue.length - 1; n >= 0; n -= 1) {
    const checkDigit = ccValue.charAt(n);
    nDigit = parseInt(checkDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }
    nCheck += nDigit;
    bEven = !bEven;
  }
  return (nCheck % 10) === 0;
};

export const isCouponApplied = order => (order && order.coupons && order.coupons.length > 0);

export const getOrderPrice = (order) => {
  let orderPrice = get(order, 'price', 0);
  const orderId = get(order, 'id');

  // If we haven't updated the currentOrder, totalPrice is sometimes 0. We should show whichever is higher, unless coupons are applied
  if (orderId && (order.totalPrice > orderPrice || isCouponApplied(order))) {
    orderPrice = order.totalPrice;
  }
  return orderPrice;
};

export const isValidEmail = email => (email.match(/^\S+@\S+\.\S+/));

export const allowDialogClose = (currentOrder) => {
  if (FeatureFlags.MenuPage.OrderFlowDialog.forceDialogVisibility && currentOrder) {
    if (currentOrder.location !== undefined && (currentOrder.isASAP || currentOrder.desiredTime)) return true;
    return false;
  }
  return true;
};

export const filterGuestOptions = optionList => optionList.filter(opt => !['rewards', 'account', 'settings', 'subscriptions'].includes(opt.key));

export const customAssign = (target, ...sources) => {
  sources.forEach((source) => {
    Object.keys(source).forEach((key) => {
      const sourceVal = source[key];
      const targetval = target[key];
      // eslint-disable-next-line no-param-reassign
      target[key] = targetval && sourceVal && typeof targetval === 'object' && typeof sourceVal === 'object'
        ? customAssign(targetval, sourceVal)
        : sourceVal;
    });
  });
  return target;
};

export const getProductListFromUpsells = (upsells, products) => {
  if (upsells.length === 0) return [];
  const result = [];
  upsells.forEach((upsell) => {
    const upsellProduct = products.find(product => product.id === upsell.productId);
    if (upsellProduct) result.push({ id: upsell.id, product: upsellProduct });
  });
  return result;
};

export const getImageUrl = (name) => {
  const hostUrl = `https://storage.googleapis.com/craver-mobile-app-images/${window.companyMenuToken}-assets/web%20app/`;
  // Use a substring of timestamp to avoid it changing too many times
  const timeStampString = String(Date.now());
  const timeStamp = timeStampString.substring(0, timeStampString.length - 4);
  const fileName = FeatureFlags.quickUpdateAssets ? `${name}?time=${timeStamp}` : name;
  return `${hostUrl}${fileName}`;
};

export const getDocumentStyleVariables = () => {
  const result = {};
  // Get the style variables as a raw string from the documents css
  // eslint-disable-next-line no-undef
  const docmuentCssText = document.documentElement.style.cssText;
  // They are all separated by semi-colon, so we can parse them and return an object
  const splitVariables = docmuentCssText.split(';');
  splitVariables.forEach((stringVariable) => {
    if (!stringVariable) return;
    let [key, value] = stringVariable.split(':');
    // Clean up key and value, remove whitespaces and the `--` character
    key = key.replace('--', '');
    key = key.replace(/\s+/, '');
    value = value.replace(/\s+/, '');
    result[key] = value;
  });
  return result;
};

export const isDarkTheme = () => {
  const styleVars = getDocumentStyleVariables();
  if (!styleVars) return false;
  return styleVars['palette-mode'] === 'dark';
};

export const getReorderItemStatus = (currentOrder, reOrderCheckResult) => {
  if (!reOrderCheckResult || !currentOrder) return null;
  // If there's no unavailable, all are available.
  if (!reOrderCheckResult.unavailableItems) return REORDER_CHECK_CASES.ALL_AVAILABLE;
  // If there's no items, none are available.
  if (!reOrderCheckResult.items && isEmpty(currentOrder.items)) return REORDER_CHECK_CASES.NONE_AVAILABLE;
  // Otherwise, some are available.
  return REORDER_CHECK_CASES.SOME_AVAILABLE;
};

export const refreshOrderPrice = async (actions, user, currentOrder) => {
  const getOrdersResponse = await actions.getResource(user.token, ['users', user.id, 'orders', currentOrder.id]);
  if (!getOrdersResponse) return;
  const refreshedOrder = getOrdersResponse.response;
  await actions.updateOrder(null, refreshedOrder, currentOrder.id);
};

// When reordering, The currentOrder settings should take preference. For example,
// if the user has set up their order to be pickup, the dialog should give an error message
// if the order is unavailable for pickup. The previous order's settings
// (asap, deliveryoption, etc.) would only take precedence if the current order is not set up.
export const getReOrderObject = (prevOrder, currentOrder) => {
  const orderLocation = get(currentOrder, 'location') || get(prevOrder, 'location');
  const orderDeliveryOption = get(currentOrder, 'deliveryOption') || get(prevOrder, 'deliveryOption');
  const orderIsASAP = currentOrder.isASAP === undefined ? prevOrder.isASAP : currentOrder.isASAP;
  const orderDesiredTime = get(currentOrder, 'desiredTime') || get(prevOrder, 'desiredTime');
  return {
    ...prevOrder,
    location: orderLocation,
    deliveryOption: orderDeliveryOption,
    isASAP: orderIsASAP,
    desiredTime: orderDesiredTime,
    reorderId: prevOrder.id,
  };
};

/**
 * getAutocompleteInfoFromPlace, takes a Place object and outputs the required information for the delivery address form.
 * @param {*} place A google Place object, see: https://developers.google.com/maps/documentation/javascript/reference/place
 * @returns the stree Address, City and Postal code of the place.
 */
export const getAutocompleteInfoFromPlace = (place) => {
  const formattedAddress = get(place, 'formatted_address', '');
  const addressComponents = get(place, 'address_components', '');
  if (!formattedAddress || !addressComponents) {
    return { streetAddress: '', city: '', postalCode: '' };
  }
  // Since the format is "street address, city, province/state, country",
  // we'll assume the second term is going to be the address' city.
  const streetAddress = formattedAddress.split(',')[0];
  const city = formattedAddress.split(',')[1];
  // The place result also contains the postal code
  const postalCodeAddressComponent = addressComponents.find(component => component.types.includes('postal_code'));
  const postalCode = get(postalCodeAddressComponent, 'short_name');
  return { streetAddress, city, postalCode };
};

export const sortTimeLimits = (timeLimit) => {
  timeLimit.sort((timeRangeOne, timeRangeTwo) => parseInt(timeRangeOne.split(':')[0], 10) - parseInt(timeRangeTwo.split(':')[0], 10));
};

export const parseSequentialDaysText = (days) => {
  let firstDay; let lastDay;
  if (days.length > 1) {
    firstDay = dayIndexToShortform(Math.min(...days));
    lastDay = dayIndexToShortform(Math.max(...days));
    return `${firstDay} - ${lastDay}`;
  }
  firstDay = dayIndexToShortform(days[0]);
  lastDay = dayIndexToShortform(days[0]);
  return `${firstDay}`;
};

export const parseNonSequentialDaysText = (days) => {
  const clonedDays = cloneDeep(days);
  const resultDays = clonedDays.map(day => dayIndexToShortform(day));
  return `${resultDays.join(', ')}`;
};

export const groupDayTimeLimits = (timeLimits) => {
  const timeLimitsMap = new Map();
  timeLimits.filter(entry => (entry.start !== '' && entry.end !== '')).forEach((limit) => {
    const { start = '', end = '' } = limit;
    const startEndLimit = `${start}, ${end}`;
    const daysIndices = limit.daysOfWeek.map(day => FULL_DAYS.indexOf(day));
    let dayRange = '';
    if (isArraySequentialByX(daysIndices, 1)) {
      dayRange = parseSequentialDaysText(daysIndices);
    } else {
      dayRange = parseNonSequentialDaysText(daysIndices);
    }

    if (timeLimitsMap.has(dayRange)) {
      timeLimitsMap.set(dayRange, [...timeLimitsMap.get(dayRange), startEndLimit]);
    } else {
      timeLimitsMap.set(dayRange, [startEndLimit]);
    }
  });
  // Sort time limits in order of startTime
  // eslint-disable-next-line no-restricted-syntax
  for (const value of timeLimitsMap.values()) {
    sortTimeLimits(value);
  }
  return timeLimitsMap;
};

export const groupDateTimeLimits = (timeLimits) => {
  const timeLimitsMap = new Map();

  [...timeLimits].sort(compareValues('startDate', 'asc')).forEach((limit) => {
    const { start = '', end = '' } = limit;
    const startDateFull = new Date(limit.startDate * 1000);
    const timeZoneOffset = startDateFull.getTimezoneOffset() * (1000 * 60);
    const startDateWithTimeZoneOffset = new Date((limit.startDate * 1000) + timeZoneOffset);

    const startMonth = invertShortMonthDict[startDateWithTimeZoneOffset.getMonth() + 1];
    const startDay = startDateWithTimeZoneOffset.getDate();
    const startYear = startDateWithTimeZoneOffset.getFullYear();

    const endDateWithTimeZoneOffset = new Date((limit.endDate * 1000) + timeZoneOffset);
    const endMonth = invertShortMonthDict[endDateWithTimeZoneOffset.getMonth() + 1];
    const endDay = endDateWithTimeZoneOffset.getDate();
    const endYear = endDateWithTimeZoneOffset.getFullYear();

    const startEndLimit = `${start}, ${end}`;
    let dateRange = '';

    if ((startDay === endDay) && (startMonth === endMonth) && (startYear === endYear)) {
      dateRange = `${startMonth} ${startDay}, ${endYear}`;
    } else if (startMonth === endMonth) {
      dateRange = `${startMonth} ${startDay} - ${endDay}, ${endYear}`;
    } else {
      dateRange = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
    }

    if (timeLimitsMap.has(dateRange)) {
      timeLimitsMap.set(dateRange, [...timeLimitsMap.get(dateRange), startEndLimit]);
    } else {
      timeLimitsMap.set(dateRange, [startEndLimit]);
    }
  });
  // Sort time limits in order of startTime
  // eslint-disable-next-line no-restricted-syntax
  for (const value of timeLimitsMap.values()) {
    sortTimeLimits(value);
  }
  return timeLimitsMap;
};

/**
 * timeLimitsValid, takes a timeLimits array and validates it, it needs to be a non-empty array.
 * @param {*} timeLimits An array of timeLimits.
 * @returns true if the timeLimits are valid, false otherwise.
 */
export const timeLimitsValid = (timeLimits) => {
  if (!timeLimits) return false;
  if (!timeLimits.length > 0) return false;
  // If using category date limits return true for at least one valid timeLimit
  if (timeLimits[0].endDate != null) {
    return FeatureFlags.useCategoryDateTimeLimits;
  }
  return true;
};

/**
 * Get order the id of the subItem, depending on the side. (right or left)
 * @param {*} item The orderItem
 * @param {*} side The side of the desired subItem, right or left.
  */
export const getSubItemIdBySide = (orderItem, side) => {
  if (!orderItem.subItems) return null;
  if (!orderItem.subItems[0]) return null;
  if (!get(orderItem, 'subItems[0].portion') && !get(orderItem, 'subItems[1].portion')) return null;
  const subItemFromSide = orderItem.subItems.find(subItem => get(subItem, 'portion.name') === side);
  if (!subItemFromSide) return null;
  return subItemFromSide.id;
};

export const getOrderCouponId = (order, allCoupons) => {
  if (allCoupons.length === 0) return null;
  const orderCoupons = get(order, 'coupons', []);
  const currentOrderDollarCoupons = orderCoupons.filter(isDollarReward);
  if (!currentOrderDollarCoupons) return null;
  const currentOrderDollarCouponIds = currentOrderDollarCoupons.map(c => c.id);
  if (!currentOrderDollarCouponIds) return null;
  // currently we only allow for a single coupon to be applied to an order
  return currentOrderDollarCouponIds[0];
};

/**
 * Parse emergency title and message from currentOrder.location if both values
 * have been set else return null
 * @param {*} currentOrder
 */
export const getEmergencyAnnouncement = (order) => {
  const announcement = { title: '', message: '' };
  if (!order || !order.location) return announcement;
  if (order.location.emergencyTitle && order.location.emergencyMessage) {
    announcement.title = order.location.emergencyTitle;
    announcement.message = order.location.emergencyMessage;
  }
  return announcement;
};
