import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, func, objectOf, string, any } from 'prop-types';
import {
  Typography,
  Card,
} from '@material-ui/core';

import * as Routes from '../../services/routes/Routes.json';
import { getNotifications } from '../../selectors';
import { isDesktopMode } from '../../services/functions/Functions';
import DateHelper from '../../services/helpers/DateHelper';
import RedirectLinkComponent from '../core/components/RedirectLinkComponent';
import '../../css/notificationsPage/NotificationPage.scss';

const NotificationContent = (props) => {
  const { translation, message } = props;
  if (!message) return null;
  return (
    <Card className="notificationPage-messageContainer">
      <Typography className="notificationPage-title">
        {message.subject}
      </Typography>
      <Typography className="notificationPage-dateTime">
        {DateHelper.getSuggestedTimeIntervalString(DateHelper.parseDateTimeStringToTimestamp(message.sentAt), translation('NotificationsPage.at'))}
      </Typography>
      <Typography className="messageBody">
        {message.messageBody}
      </Typography>
    </Card>
  );
};

const NotificationContainer = (props) => {
  const {
    history,
    user,
    translation,
    notifications,
    width,
    upliftHistory,
  } = props;

  useEffect(() => {
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);
  }, []);

  const notification = notifications.find(note => note.id === parseInt(props.match.params.notificationId, 10));

  if (user && user.guestToken) {
    history.push(Routes.path.menuPage);
  }
  if (!user) history.push(Routes.path.menuPage);
  else if (isDesktopMode(width)) history.push(Routes.path.notificationsPage);

  return (
    <div className="notificationPage-outerContainer">
      <div className="notificationPage-linkContainer">
        <RedirectLinkComponent
          href="/notifications"
          textClassName="notificationPage-linkText"
          linkText={translation('NotificationsPage.NotificationPage.back')}
        />
      </div>
      <NotificationContent
        {...props}
        message={notification}
      />
    </div>
  );
};

NotificationContainer.propTypes = {
  translation: func.isRequired,
  history: objectOf(any).isRequired,
  upliftHistory: func.isRequired,
  user: objectOf(any),
  notifications: arrayOf(objectOf(any)),
  width: string,
};

NotificationContent.propTypes = {
  translation: func.isRequired,
  message: objectOf(any).isRequired,
};

NotificationContainer.defaultProps = {
  user: null,
  notifications: [],
  width: '',
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const NotificationPage = compose(connect(mapStateToProps))(withRouter(NotificationContainer));

export default NotificationPage;
