import { useSelector } from 'react-redux';
import { getUserToken } from '../../selectors';
import useCloudEventsBaseParams from '../hooks/useCloudEventsBaseParams';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import getClickEventClientXY from '../utils/getClickEventClientXY';
import ClickNotificationCTAEvent from './ClickNotificationCTAEvent';
import ClickNotificationCTAActionSheetEvent from './ClickNotificationCTAActionSheetEvent';

const handleHyperLink = (url, setError, translation) => {
  // currently we are not handling deep links on web: https://aytech.atlassian.net/browse/CRV-23777
  if (URL.canParse(url)) {
    const windowProxy = window.open(url, '_blank');
    if (!windowProxy) {
      // Throw error if window did not open
      setError(translation('NotificationsPageV2.errorTexts.invalidURLError'));
    } else {
      windowProxy.focus();
    }
  } else {
    // Throw error if URL is not valid
    setError(translation('NotificationsPageV2.errorTexts.invalidURLError'));
  }
};

export const useHandleClickCTA = () => {
  const cloudEventSubjectParams = useCloudEventsBaseParams();
  const userToken = useSelector(getUserToken);

  const handleClickCTA = ({
    clickEvent,
    url,
    id,
    setError,
    translation,
  }) => {
    const positionParams = getClickEventClientXY(clickEvent);
    const cloudEvent = new ClickNotificationCTAEvent({
      ...cloudEventSubjectParams,
      ...positionParams,
      notificationId: id,
      link: url,
    });
    CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    handleHyperLink(url, setError, translation);
  };
  return handleClickCTA;
};

export const useHandleClickCTAActionSheet = () => {
  const cloudEventSubjectParams = useCloudEventsBaseParams();
  const userToken = useSelector(getUserToken);

  const handleClickCTA = ({
    clickEvent,
    url,
    id,
    setError,
    translation,
  }) => {
    const positionParams = getClickEventClientXY(clickEvent);
    const cloudEvent = new ClickNotificationCTAActionSheetEvent({
      ...cloudEventSubjectParams,
      ...positionParams,
      notificationId: id,
      link: url,
    });
    CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    handleHyperLink(url, setError, translation);
  };
  return handleClickCTA;
};
