import React from 'react';
import {
  CardMedia,
  Typography,
  ListItem,
} from '@material-ui/core';
import { func, string, bool } from 'prop-types';
import getThemedClassname from '../../utils/themedClassnames';
import '../../css/shared/ListItemCard.scss';
import ListItemCardMobile from './ListItemCardMobile';

const getListItemStyle = (isSelected) => {
  if (isSelected) {
    return getThemedClassname('listItemCardSelected');
  }
  return getThemedClassname('listItemCardUnselected');
};

const ListItemCard = (props) => {
  const {
    isRead,
    isSelected,
    title,
    body,
    date,
    imageURL,
    onClick,
    isMobile,
  } = props;
  const hasImage = Boolean(imageURL);

  if (isMobile) {
    return <ListItemCardMobile {...props} />;
  }

  return (
    <ListItem
      className={getListItemStyle(isSelected)}
      onClick={onClick}
    >
      <div className="listItemCardOuterCardContainer">
        <div className="listItemCardTitleAndSubtitleToDateSpacerContainer">
          <div className="listItemCardTitleAndSubtitleContainer">
            {
              !isRead && (
                <div className="listItemCardNewDot" />
              )
            }
            <Typography className={getThemedClassname(hasImage ? 'listItemCardTitleWithImage' : 'listItemCardTitle')}>
              {title}
            </Typography>
            <Typography className={getThemedClassname('listItemCardBody')}>
              {body}
            </Typography>
          </div>
          <Typography className={getThemedClassname('listItemCardTime')}>
            {date}
          </Typography>
        </div>
        {
          hasImage && (
            <CardMedia
              className="listItemCardImage"
              component="img"
              src={imageURL}
            />
          )
        }
      </div>
    </ListItem>
  );
};

ListItemCard.propTypes = {
  onClick: func.isRequired,
  title: string.isRequired,
  body: string.isRequired,
  date: string.isRequired,
  isMobile: bool.isRequired,
  imageURL: string,
  isSelected: bool,
  isRead: bool,
};

ListItemCard.defaultProps = {
  isSelected: false,
  imageURL: '',
  isRead: false,
};

export default ListItemCard;
