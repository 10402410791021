import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Icon } from '@material-ui/core';
import '../../../css/core/components/CloseButton.scss';

const CloseButtonComponent = (props) => {
  const { handleButtonClose } = props;

  return (
    <div className="closeButton-container">
      <IconButton
        key="close"
        aria-label="close"
        className="closeButton-button"
        onClick={handleButtonClose}
      >
        <Icon>
          close
        </Icon>
      </IconButton>
    </div>
  );
};

CloseButtonComponent.propTypes = {
  handleButtonClose: PropTypes.func,
};

CloseButtonComponent.defaultProps = {
  handleButtonClose: () => {},
};

export default CloseButtonComponent;
