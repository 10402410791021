import React from 'react';
import { Typography } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import getThemedClassname from '../../utils/themedClassnames';
import '../../css/shared/SelectableChip.scss';

const getChipBorderStyle = isSelected => (
  isSelected
    ? 'selectableChip-container-selected'
    : 'selectableChip-container-unselected'
);
const getChipLabelStyle = isSelected => (
  isSelected
    ? 'selectableChip-label-selected'
    : 'selectableChip-label-unselected'
);

const SelectableChip = ({
  isSelected,
  label,
  onClick,
}) => (
  <div
    className={getThemedClassname(getChipBorderStyle(isSelected))}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onClick();
      }
    }}
  >
    <Typography className={getChipLabelStyle(isSelected)}>
      {label}
    </Typography>
  </div>
);

SelectableChip.propTypes = {
  label: string.isRequired,
  isSelected: bool.isRequired,
  onClick: func,
};

SelectableChip.defaultProps = {
  onClick: () => {},
};

export default SelectableChip;
