import { handleResponse } from '../../ResponseHandler';
import getPageHeightWidth from './getPageHeightWidth';
import { getBaseUrl } from '../../helpers/PathUtils';

const hostWithApiVersion = window.api_host;
const baseUrl = getBaseUrl(hostWithApiVersion);

const EVENTS_PATH = '/v2/events';
const { companyApiKey } = window;

export function generateHeader(userToken) {
  const apiHeader = {};
  const finalToken = userToken || companyApiKey;

  apiHeader.Authorization = `Bearer ${finalToken}`;
  apiHeader['Content-Type'] = 'application/json';
  return apiHeader;
}

export const sendCloudEvent = ({
  cloudEvent,
  userToken,
  dateSeam,
  requestBodyLoggerFuncSeam,
}) => {
  cloudEvent.stamp(dateSeam);

  const path = baseUrl + EVENTS_PATH;
  const body = JSON.stringify(cloudEvent);

  // Makes request bodies more testable,
  // otherwise by the time they are passed to the new Request() body param, they become ReadableStreams
  if (typeof requestBodyLoggerFuncSeam === 'function') {
    requestBodyLoggerFuncSeam(body);
  }
  const request = (
    new Request(path, {
      method: 'POST',
      headers: generateHeader(userToken),
      body,
    })
  );

  return handleResponse(request, body, false, true);
};

export const getSubject = ({
  itemId,
  merchantId,
  orderId,
  userId,
}) => {
  if (merchantId && userId && orderId && itemId) {
    return `merchants/${merchantId}/users/${userId}/orders/${orderId}/items/${itemId}`;
  }
  if (merchantId && userId && orderId) {
    return `merchants/${merchantId}/users/${userId}/orders/${orderId}`;
  }
  if (merchantId && userId) {
    return `merchants/${merchantId}/users/${userId}`;
  }
  if (merchantId) {
    return `merchants/${merchantId}`;
  }
  return '';
};

export const getPositionObject = ({ clientX, clientY }) => ({
  clientX,
  clientY,
  ...getPageHeightWidth(),
});
