import React, { useState, useEffect, Fragment } from 'react';
import isSameDay from 'date-fns/isSameDay';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import Immutable from 'seamless-immutable';
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Typography,
  Drawer,
  Divider,
  Icon,
  IconButton,
  CircularProgress,
  TextField,
  withWidth,
  ButtonGroup,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {
  func,
  objectOf,
  bool,
  any,
  arrayOf,
  number,
} from 'prop-types';

import * as Routes from '../../../services/routes/Routes.json';
import AmexIcon from '../../../assets/images/american-express-1.png';
import GiftCardIcon from '../../../assets/images/primary_logo.svg';
import MasterIcon from '../../../assets/images/master.svg';
import VisaIcon from '../../../assets/images/visa.svg';
import Button from './Button';
import LinkText from './LinkText';
import TabBar from './TabBar';
import OrderHelper from '../../../services/helpers/OrderHelper';
import SubTotalSummary from './SubTotalSummary';
import OrderItemList from './OrderItemList';
import DeleteDialog from '../../paymentPage/components/DeleteDialog';
import SquarePaymentComponent from '../../paymentPage/subComponents/SquarePaymentComponent';
import BamboraPaymentComponent from '../../paymentPage/subComponents/BamboraPaymentComponent';
import StripeNewCardForm from '../../paymentPage/subComponents/StripeNewCardForm/StripeNewCardForm';
import SquareGiftCardComponent from '../../paymentPage/subComponents/SquareGiftCardComponent';
import CraverGiftCardForm from '../../paymentPage/subComponents/CraverGiftCardForm';
import ToastPaymentComponent from '../../paymentPage/subComponents/ToastPaymentComponent';
import CheckBoxComponent from './CheckBoxComponent';
import CraverCreditCardForm from './CraverCreditCardForm';
import {
  getCompany,
  getCurrentOrder,
  getCurrentOrderItems,
  getCurrentUserPaymentOptions,
  getUser,
  getIsPaymentDrawerVisible,
  getLoading,
} from '../../../selectors';
import {
  getFormattedPrice,
  formatEpoch,
  getPayAtDoorOptionsObject,
  customDecode,
  generateKey,
  getTipAmount,
  setDefaultTipAmount,
} from '../../../services/functions/Functions';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import { DINE_IN } from '../../../services/constants/Constants';

import '../../../css/core/components/PaymentDrawer.scss';
import useCloudEventsBaseParams from '../../../events/hooks/useCloudEventsBaseParams';
import ClickPlaceOrderButtonEvent from '../../../events/Payment/ClickPlaceOrderButtonEvent';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';


const goBack = (actions) => {
  actions.toggleComponent('PaymentDrawer');
  actions.toggleComponent('CheckoutDrawer');
};

const LoadingOverlay = () => (
  <div className="paymentDrawer-loadingOverlay">
    <div className="paymentDrawer-circularProgressContainer">
      <CircularProgress />
    </div>
  </div>
);

const DrawerTitle = ({ actions, translation }) => (
  <div className="paymentDrawer-titleContainer">
    <IconButton
      className="paymentDrawer-headerIcon"
      onClick={() => goBack(actions)}
    >
      <Icon>arrow_back_ios</Icon>
    </IconButton>
    <Typography className="paymentDrawer-headerTitle">
      {translation('PaymentDrawer.title')}
    </Typography>
    <IconButton
      className="paymentDrawer-headerIcon"
      onClick={() => actions.toggleComponent('PaymentDrawer')}
    >
      <Icon>close</Icon>
    </IconButton>
  </div>
);

const RadioLabel = ({
  paymentOption, showDeleteDialog, translation,
}) => {
  const paymentOptionIcons = {
    visa: VisaIcon,
    mastercard: MasterIcon,
    amex: AmexIcon,
    gift_card: GiftCardIcon,
  };
  // eslint-disable-next-line no-undef
  const currencySymbol = window.companyCurrencySymbol ? customDecode(window.companyCurrencySymbol) : '$';

  if (!paymentOption.cardType) {
    return (
      <div className="paymentDrawer-cardLabel">
        <Icon
          className="paymentDrawer-cardIcon"
        >
          {paymentOption.icon}
        </Icon>
        <Typography className="paymentDrawer-drawerText">
          {paymentOption.name}
        </Typography>
      </div>
    );
  }
  return (
    <div className="paymentDrawer-cardLabel">
      <img
        src={paymentOptionIcons[paymentOption.cardType.toLowerCase()]}
        alt={paymentOption.cardType}
        className="paymentDrawer-cardIcon"
      />
      <div>
        <Typography className="paymentDrawer-drawerText">
          {paymentOption.cardNumber}
        </Typography>
        {
          paymentOption.cardType === 'GIFT_CARD' && (
            <Typography className="paymentDrawer-drawerText">
              {`${translation('PaymentDrawer.balance')} : ${currencySymbol}${paymentOption.balance}`}
            </Typography>
          )
        }
      </div>
      <IconButton
        className="paymentDrawer-drawerText"
        onClick={() => showDeleteDialog(paymentOption)}
      >
        <Icon>delete</Icon>
      </IconButton>
    </div>
  );
};

const PayAtDoorOptions = ({
  translation,
  currentOrder,
  paymentOptionId,
  setPaymentOptionId,
  showDeleteDialog,
}) => {
  let payAtDoorOptions = [];
  const orderLocation = get(currentOrder, 'location', {});
  const { payAtDoorAvailable } = orderLocation;

  if (payAtDoorAvailable) {
    payAtDoorOptions = getPayAtDoorOptionsObject(orderLocation, translation);
  }

  return (
    <div className="paymentDrawer-sectionContainer">
      <div className="paymentDrawer-sectionTitle">
        <Typography className="paymentDrawer-sectionTitleText">
          {translation('PaymentDrawer.drawerPayment')}
        </Typography>
      </div>
      <div className="paymentDrawer-sectionContent">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment_option"
            name="payment_option"
            value={paymentOptionId}
            onChange={event => setPaymentOptionId(event.target.value)}
          >
            {
              payAtDoorOptions.map(paymentOption => (
                <FormControlLabel
                  key={paymentOption.id}
                  value={`${paymentOption.id}`}
                  control={<Radio />}
                  label={<RadioLabel showDeleteDialog={showDeleteDialog} paymentOption={paymentOption} translation={translation} />}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

const PayNowOptions = ({
  actions,
  translation,
  currentOrder,
  paymentOptions,
  user,
  paymentOptionId,
  setPaymentOptionId,
  giftCardOption,
  setGiftCardOption,
  showDeleteDialog,
}) => {
  const orderLocation = get(currentOrder, 'location', {});
  const { payNowAvailable } = orderLocation;

  useEffect(() => {
    if (user && payNowAvailable) {
      actions.getAllResources(user.token, ['users', user.id, 'payment_options']);
    }
  }, []);

  const giftCardTabs = [
    { id: 'CARD', label: translation('PaymentMethodComponent.creditCard') },
    { id: 'GIFT_CARD', label: translation('PaymentMethodComponent.giftCard') },
  ];

  const creditCards = paymentOptions.filter(opt => (opt.cardType !== 'GIFT_CARD'));
  const giftCards = paymentOptions.filter(opt => (opt.cardType === 'GIFT_CARD'));

  const allOptions = giftCardOption === 'CARD' ? creditCards : giftCards;

  return (
    <div className="paymentDrawer-sectionContainer">
      <div className="paymentDrawer-sectionTitle">
        <Typography className="paymentDrawer-sectionTitleText">
          {translation('PaymentDrawer.drawerPayment')}
        </Typography>
      </div>
      {
        FeatureFlags.CoreView.PaymentDrawer.enableGiftCard
        && (
          <TabBar
            selected={giftCardOption}
            onClickSelected={value => setGiftCardOption(value)}
            tabs={giftCardTabs}
            translation={translation}
          />
        )
      }
      <div className="paymentDrawer-sectionContent">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment_option"
            name="payment_option"
            value={paymentOptionId}
            onChange={event => setPaymentOptionId(event.target.value)}
          >
            {
              allOptions.map(paymentOption => (
                <FormControlLabel
                  key={paymentOption.id}
                  value={`${paymentOption.id}`}
                  control={<Radio />}
                  label={<RadioLabel showDeleteDialog={showDeleteDialog} paymentOption={paymentOption} translation={translation} />}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

const PaymentForm = ({
  translation, user, actions, currentOrder, company, setPaymentOptionId, giftCardOption, handleProcessPayment,
}) => {
  const commonProps = {
    user, actions, currentOrder, translation, company,
  };

  const handleAddCard = async (newCard) => {
    if (!user) return;
    actions.getAllResources(user.token, ['users', user.id, 'payment_options']).then(() => {
      setPaymentOptionId(`${newCard.id}`);
    });
  };

  const renderCreditCardForm = (paymentIntegration) => {
    switch (paymentIntegration) {
      case 'SQUARE':
        return <SquarePaymentComponent handleAddCard={handleAddCard} handleProcessPayment={handleProcessPayment} {...commonProps} />;
      case 'TOAST':
        return <ToastPaymentComponent handleAddCard={handleAddCard} {...commonProps} />;
      case 'STRIPE':
        return <StripeNewCardForm handleAddCard={handleAddCard} {...commonProps} />;
      case 'BAMBORA':
        return <BamboraPaymentComponent {...commonProps} />;
      case 'NONE':
      default:
        return <CraverCreditCardForm {...commonProps} />;
    }
  };

  const renderGiftCardForm = (giftCardIntegration) => {
    switch (giftCardIntegration) {
      case 'SQUARE':
        return (
          <SquareGiftCardComponent
            translation={translation}
            user={user}
            actions={actions}
            currentOrder={currentOrder}
          />
        );
      case 'FACTOR4':
      case 'CRAVER':
        return <CraverGiftCardForm giftCardIntegration={giftCardIntegration} {...commonProps} />;
      // If there's no gift card integration, return null.
      case 'NONE':
      default:
        console.log('No gift card integration');
        return null;
    }
  };

  const paymentIntegration = get(company, 'paymentIntegration', 'NONE');
  const giftCardIntegration = get(company, 'giftCardIntegration', 'NONE');
  return (
    <div className="paymentDrawer-sectionContainer">
      <div className="paymentDrawer-sectionTitle">
        <Typography className="paymentDrawer-sectionTitleText">
          {translation('PaymentDrawer.drawerPaymentInfo')}
        </Typography>
      </div>
      {
        giftCardOption === 'CARD'
          ? renderCreditCardForm(paymentIntegration)
          : renderGiftCardForm(giftCardIntegration)
      }
    </div>
  );
};

const OrderSummary = ({
  actions, currentOrder, translation,
}) => (
  <div className="paymentDrawer-sectionContainer">
    <div className="paymentDrawer-sectionTitle">
      <Typography className="paymentDrawer-sectionTitleText">
        {translation('PaymentDrawer.drawerSummary')}
      </Typography>
      <LinkText
        onClick={() => goBack(actions)}
        className="paymentDrawer-linkText"
        text={translation('edit')}
      />
    </div>
    <div className="paymentDrawer-sectionContent">
      <OrderItemList
        orderItems={currentOrder.items}
        translation={translation}
        enableEdit={false}
      />
    </div>
  </div>
);

const PickupInformation = ({
  actions, currentOrder, translation,
}) => {
  const { deliveryOption, desiredTime, isASAP } = currentOrder;
  const isDeliveredOrder = OrderHelper.isDeliveredOrder(deliveryOption);
  const isDineIn = deliveryOption === DINE_IN;

  // Location text (name, address)
  const firstRowText = isDeliveredOrder
    ? get(currentOrder, 'address.nickname')
    : get(currentOrder, 'location.name');
  const secondRowText = isDeliveredOrder
    ? get(currentOrder, 'address.streetAddress')
    : get(currentOrder, 'location.address');
  let thirdRowText;
  if (isDineIn) thirdRowText = currentOrder.tableNumber;

  // Order Time text (day, hour)
  const desiredDate = formatEpoch(desiredTime);
  let dayOptionText = desiredDate.toLocaleDateString([], { weekday: 'long', month: 'long', day: '2-digit' });
  let hourText = desiredDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  if (isSameDay(desiredDate, new Date())) dayOptionText = translation('today');
  if (isASAP) {
    dayOptionText = translation('today');
    hourText = translation('ASAP');
  }
  const infoText = `${translation(deliveryOption)} ${translation('PaymentDrawer.drawerInfo')}`;
  return (
    <div className="paymentDrawer-sectionContainer">
      <div className="paymentDrawer-sectionTitle">
        <Typography className="paymentDrawer-sectionTitleText">
          {infoText}
        </Typography>
        <LinkText
          onClick={() => goBack(actions)}
          className="paymentDrawer-linkText"
          text={translation('edit')}
        />
      </div>
      <div className="paymentDrawer-sectionContent">
        <Typography className="paymentDrawer-drawerText">
          {firstRowText}
        </Typography>
        <Typography className="paymentDrawer-drawerText">
          {secondRowText}
        </Typography>
        {
          thirdRowText
          && (
            <Typography className="paymentDrawer-drawerText">
              {thirdRowText}
            </Typography>
          )
        }
        <div className="paymentDrawer-orderTime">
          <div className="paymentDrawer-iconText">
            <Icon className="paymentDrawer-drawerText">event</Icon>
            <Typography className="paymentDrawer-drawerText">
              {dayOptionText}
            </Typography>
          </div>
          <div className="paymentDrawer-iconText">
            <Icon className="paymentDrawer-drawerText">access_time</Icon>
            <Typography className="paymentDrawer-drawerText">
              {hourText}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};


const OrderInstructions = ({
  actions, currentOrder, translation,
}) => (
  <div className="paymentDrawer-sectionContainer">
    <div className="paymentDrawer-sectionTitle">
      <Typography className="paymentDrawer-sectionTitleText">{translation('PaymentDrawer.drawerInstructions')}</Typography>
      <LinkText
        onClick={() => goBack(actions)}
        className="paymentDrawer-linkText"
        text={translation('edit')}
      />
    </div>
    <div className="paymentDrawer-sectionContent">
      <Typography className="paymentDrawer-drawerText">
        {get(currentOrder, 'note')}
      </Typography>
    </div>
    <Divider />
  </div>
);

const GuestCheckoutRegister = (props) => {
  const {
    translation,
    setGuestPassword,
    setShowGuestCheckout,
    showGuestCheckout,
    setSavePaymentInfo,
    setGuestSpecialOffers,
  } = props;
  const guestSetterFunctions = {
    savePaymentInfo: setSavePaymentInfo,
    guestSpecialOffers: setGuestSpecialOffers,
  };

  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const { guestCheckoutOptions, guestCheckoutIncentives } = FeatureFlags.ResourceConstants;

  return (
    <div className="paymentDrawer-guestCheckoutContainer">
      <div className="paymentDrawer-sectionTitleContainer">
        <Typography className="paymentDrawer-sectionTitleText">
          {`${translation('PaymentDrawer.guestCheckoutForm.optional')}:`}
        </Typography>
        <IconButton
          className="paymentDrawer-headerIcon"
          onClick={() => setShowGuestCheckout(!showGuestCheckout)}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
      <Typography className="paymentDrawer-sectionTitleText">
        {translation('PaymentDrawer.guestCheckoutForm.checkoutFaster')}
      </Typography>
      <Typography className="paymentDrawer-sectionSubtitleText">
        {translation('PaymentDrawer.guestCheckoutForm.almostThere')}
      </Typography>
      <div className="paymentDrawer-incentiveContainer">
        {
          guestCheckoutIncentives.map((incentive, i) => (
            <div className="paymentDrawer-incentiveItem" key={generateKey(i)}>
              <Icon className="paymentDrawer-incentiveIcon">
                {incentive.icon}
              </Icon>
              <Typography className="paymentDrawer-incentiveText">
                {incentive.text}
              </Typography>
            </div>
          ))
        }
      </div>
      <TextField
        id="outlined-password-input"
        variant="outlined"
        type="password"
        placeholder={translation('PaymentDrawer.guestCheckoutForm.password')}
        onChange={event => setGuestPassword(event.target.value)}
        className="paymentDrawer-textFieldStyle"
      />
      <div className="paymentDrawer-passwordLabelContainer">
        <Icon className="paymentDrawer-incentiveIcon">check_circle</Icon>
        <Typography className="paymentDrawer-passwordLabel">
          {translation('PaymentDrawer.guestCheckoutForm.passwordFieldLabel')}
        </Typography>
      </div>
      {
        guestCheckoutOptions.map(option => (
          <CheckBoxComponent
            id={option.id}
            key={generateKey(option.id)}
            field={{ ...option, title: option.label }}
            handleChange={() => guestSetterFunctions[option.title](!props[option.title])}
            selectedValue={props[option.title]}
            formControlLabelStyle="paymentDrawer-formControlLabelStyle"
            labelStyle="paymentDrawer-labelTextStyle"
            checkboxContentStyle="paymentDrawer-checkboxContent"
          />
        ))
      }
    </div>
  );
};

const PlaceOrderButton = ({
  translation, currentOrder, processPayment, paymentOptionId, loading,
}) => {
  const orderTotalPrice = get(currentOrder, 'totalPrice') || 0;

  return (
    <Button
      disabled={paymentOptionId === '' || loading}
      fullWidth
      onClick={processPayment}
      keepclickeventonclick
      type="primary"
      text={`${translation('PaymentDrawer.placeOrder')} (${currentOrder ? getFormattedPrice(orderTotalPrice) : ''})`}
    />
  );
};

const PaymentTypeSelector = (props) => {
  const {
    setPayNowOrPayAtDoor, setPaymentOptionId, payNowOrPayAtDoor, translation,
  } = props;

  const handleTypeChange = (event) => {
    setPaymentOptionId('');
    setPayNowOrPayAtDoor(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <Typography className="paymentDrawer-sectionTitleText">
        {translation('PaymentDrawer.drawerPaymentSelection')}
      </Typography>
      <RadioGroup aria-label="paynow-or-at-door" name="paymentSelector" value={payNowOrPayAtDoor} onChange={handleTypeChange}>
        <FormControlLabel
          value="payNow"
          control={<Radio />}
          label={translation('PaymentMethodComponent.payNow')}
        />
        <FormControlLabel
          value="payAtDoor"
          control={<Radio />}
          label={translation('PaymentMethodComponent.payAtDoor')}
        />
      </RadioGroup>
    </FormControl>
  );
};

const getTipOptionText = (props) => {
  const { tipOption, translation } = props;
  if (tipOption.id === 0) {
    return translation('TipDialog.noTip');
  } else if (tipOption.id === 'customize') {
    return translation('custom');
  }
  return tipOption.key;
};

const TipOption = (props) => {
  const {
    tipOption, isSelected, setSelectedTip,
  } = props;

  // ButtonGroup from TipOptions passes these props to it's children
  const muiButtonProps = {
    className: props.className,
    classes: {
      label: isSelected ? 'paymentDrawer-selectedTipLabel' : 'paymentDrawer-unSelectedTipLabel',
    },
    disabled: props.disabled,
    color: props.color,
    disableFocusRipple: props.disableFocusRipple,
    disableRipple: props.disableRipple,
    fullWidth: props.fullWidth,
    size: props.size,
    variant: props.variant,
  };

  return (
    <Button
      {...muiButtonProps}
      overrideClass
      hasBorderRadius={false}
      hasBorderColor={false}
      type="secondary"
      className={isSelected ? 'paymentDrawer-selectedTipOverride' : 'paymentDrawer-unSelectedTipOverride'}
      selected={isSelected}
      onClick={() => setSelectedTip(tipOption.id)}
      text={getTipOptionText(props)}
    />
  );
};

const CustomTipInput = (props) => {
  const { onChange, inputRef } = props;

  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      allowNegative={false}
      decimalScale={2}
    />
  );
};

const TipInput = (props) => {
  const {
    translation, currentOrder, user, actions,
  } = props;

  const [tipAmountInput, setTipAmountInput] = useState('');

  const updateOrderTipAmount = (tipAmount) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.tipAmount = tipAmount;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  return (
    <form noValidate autoComplete="off" className="paymentDrawer-tipInputForm">
      <div className="paymentDrawer-tipFormContainer">
        <TextField
          className="paymentDrawer-tipInput"
          value={tipAmountInput.numberformat}
          onChange={event => setTipAmountInput(event.target.value)}
          name="numberformat"
          id="tip-input"
          InputProps={{
            inputComponent: CustomTipInput,
            disableUnderline: true,
          }}
        />
      </div>
      <Button
        type="primary"
        fullWidth
        onClick={() => updateOrderTipAmount(tipAmountInput)}
        text={translation('save')}
        overrideClass
        className="paymentDrawer-tipInputButton"
      />
    </form>
  );
};

const TipOptions = (props) => {
  const {
    currentOrder,
    actions,
    user,
  } = props;
  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const { tipOptions, smartTipOptions, smartTipThreshold, defaultTipIndex } = FeatureFlags.ResourceConstants;
  const useSmartTip = FeatureFlags.CoreView.PaymentDrawer.enableSmartTip && (currentOrder.totalPrice <= smartTipThreshold);
  const defaultSelectedTipId = tipOptions[defaultTipIndex].id;

  const [selectedTip, setSelectedTip] = useState(defaultSelectedTipId);

  useEffect(() => {
    const setDefaultTip = async () => {
      const newOrderWithTip = setDefaultTipAmount(currentOrder);
      await actions.updateOrder(user, newOrderWithTip, currentOrder.id);
    };
    setDefaultTip();
  }, []);

  const options = useSmartTip
    ? smartTipOptions
    : tipOptions;

  const updateOrderTip = async (tipOptionId) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.tipAmount = getTipAmount(tipOptionId, currentOrder, useSmartTip);
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  const [isCustomTipVisible, setIsCustomTipVisible] = useState(false);

  const handleClickTipOption = (tipOptionId) => {
    setSelectedTip(tipOptionId);
    if (tipOptionId === 'customize' && !isCustomTipVisible) {
      setIsCustomTipVisible(!isCustomTipVisible);
    } else if (tipOptionId !== 'customize' && isCustomTipVisible) {
      setIsCustomTipVisible(!isCustomTipVisible);
      updateOrderTip(tipOptionId);
    } else {
      updateOrderTip(tipOptionId);
    }
  };

  return (
    <Fragment>
      <ButtonGroup fullWidth className="paymentDrawer-tipButtonGroup">
        {
          options.map((tipOption, i) => (
            <TipOption
              {...props}
              tipOption={tipOption}
              isSelected={tipOption.id === selectedTip}
              setSelectedTip={handleClickTipOption}
              key={generateKey(i)}
            />
          ))
        }
      </ButtonGroup>
      {
        isCustomTipVisible && (
          <TipInput
            {...props}
          />
        )
      }
    </Fragment>
  );
};

const PaymentDrawer = (props) => {
  const {
    actions,
    company,
    isDesktop,
    open,
    currentOrder = {},
    translation,
    loading,
    history,
    paymentOptions,
    user,
  } = props;

  const hasPayAtDoor = get(currentOrder, 'location.payAtDoorAvailable');
  const hasPayNow = get(currentOrder, 'location.payNowAvailable');
  const usePaymentSelector = hasPayAtDoor && hasPayNow;
  let defaultPaymentType = 'payNow';
  if (!hasPayNow && hasPayAtDoor) defaultPaymentType = 'payAtDoor';

  const [payNowOrPayAtDoor, setPayNowOrPayAtDoor] = useState(defaultPaymentType);
  const [paymentOptionId, setPaymentOptionId] = useState('');
  const [giftCardOption, setGiftCardOption] = useState('CARD');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [paymentOptionToDelete, setPaymentOptionToDelete] = useState(null);
  const [savePaymentInfo, setSavePaymentInfo] = useState(false);
  const [guestPassword, setGuestPassword] = useState(null);
  const [guestSpecialOffers, setGuestSpecialOffers] = useState(false);
  const [showGuestCheckout, setShowGuestCheckout] = useState(true);
  // TODO: CRV-11813 Refactor to enable tipping by delivery option type (incl curbside pickup)
  // and this will require show/hide tipping if curbside is toggled on/off

  useEffect(() => {
    if (!user) return;
    if (!company) {
      actions.getAllResources(user.token, ['companies']);
    }
    if (user.guestToken) {
      setSavePaymentInfo(false);
    } else {
      setSavePaymentInfo(true);
    }
  }, [open]);

  const payAtDoorOptionsString = get(currentOrder, 'location.payAtDoorOptions', '');
  const payAtDoorOptions = payAtDoorOptionsString ? payAtDoorOptionsString.split(',') : [];

  const type = payAtDoorOptions.includes(paymentOptionId) ? 'payAtDoor' : 'payNow';
  // TO-DO: Handle user points
  const points = get(user, 'points');
  const cloudEventsBaseParams = useCloudEventsBaseParams();

  // This comes directly from PaymentPage :P
  const processPayment = async (digitalWalletPaymentMethod = null, clickEvent) => {
    if (!digitalWalletPaymentMethod && !paymentOptionId) return;
    if (!digitalWalletPaymentMethod) {
      const cloudEvent = new ClickPlaceOrderButtonEvent({
        ...cloudEventsBaseParams,
        ...getClickEventClientXY(clickEvent),
      });
      CloudEventsApi.sendCloudEvent({
        cloudEvent,
        userToken: user && user.token,
      });
    }
    let paymentMethod;
    const selectedPaymentOption = paymentOptions.find(payOpt => payOpt.id === parseInt(paymentOptionId, 10));
    switch (type) {
      case 'payNow':
        if (digitalWalletPaymentMethod) {
          paymentMethod = digitalWalletPaymentMethod;
        } else {
          paymentMethod = {
            id: selectedPaymentOption.id,
            nonce: selectedPaymentOption.vaultKey,
            cvn: '123',
          };
        }
        break;
      case 'payAtDoor':
        paymentMethod = {
          name: paymentOptionId,
        };
        break;
      case 'newCard':
        paymentMethod = {
          name: selectedPaymentOption.name,
          cardNumber: selectedPaymentOption.cardNumber,
          cvc: selectedPaymentOption.cvc,
          expiryMonth: selectedPaymentOption.expiryDate.slice(0, 2),
          expiryYear: selectedPaymentOption.expiryDate.slice(2),
          streetNumber: selectedPaymentOption.streetNameNumber.split(/ (.*)/)[0],
          streetName: selectedPaymentOption.streetNameNumber.split(/ (.*)/)[1],
        };
        break;
      default: break;
    }

    if (user && paymentMethod && currentOrder.id) {
      try {
        // Create paymentOption if we're using a new card.
        if (type === 'newCard') {
          const newCardResponse = await actions
            .addResource(
              user.token, paymentMethod,
              'users', user.id,
              'payment_options',
            );
          if (!newCardResponse || newCardResponse.error) return;
          paymentMethod = {
            id: newCardResponse.id,
            nonce: newCardResponse.vaultKey,
            cvn: '123',
          };
        }
        if (loading) return;
        const paymentResponse = await actions
          .makePayment(user, paymentMethod, [
            'users', user.id,
            'orders', currentOrder.id,
            'place',
          ]);
        if (!paymentResponse || paymentResponse.error) return;
        // Update user's points (user.points) if order was successfully placed
        // Only update redux state, no API calls needed. Need to make a copy
        // of the existing user object to make sure no other changes are made.
        const updatedUserObj = Immutable.asMutable(user, { deep: true });
        updatedUserObj.points = points;
        const updateUserResponse = actions.updateUser(user.token, updatedUserObj);
        if (!updateUserResponse || updateUserResponse.error) return;
        actions.toggleComponent('PaymentDrawer');

        // Handle guest User
        if (guestPassword) {
          const newUserObj = {
            name: user.name,
            email: user.email,
            password: guestPassword,
            telephone: user.telephone,
          };
          await actions.signUpUser(newUserObj);
        }
        // Delete payment info for guest user
        if (!savePaymentInfo) {
          paymentOptions.forEach((paymentOption) => {
            actions.deleteResource(user, ['users', user.id, 'payment_options', paymentOption.id]);
          });
        }
        history.push(Routes.path.orderStatusPage);
      } catch (error) {
        console.log('API call error', error);
      }
    }
  };

  const showDeleteDialog = (payOpt) => {
    setPaymentOptionToDelete(payOpt);
    setDeleteDialogOpen(true);
  };

  const deletePaymentOption = () => {
    if (user) {
      actions.deleteResource(user, ['users', user.id, 'payment_options', paymentOptionToDelete.id]);
      setDeleteDialogOpen(false);
      setPaymentOptionId('');
    }
  };

  if (!currentOrder || !user) return null;

  return (
    <Drawer
      classes={{
        paper: 'paymentDrawer',
      }}
      open={open}
      onClose={() => actions.toggleComponent('PaymentDrawer')}
      anchor={isDesktop ? 'right' : 'bottom'}
    >
      {
        loading !== 0 && (
          <LoadingOverlay {...props} />
        )
      }
      <div className="paymentDrawer-header">
        <DrawerTitle {...props} />
      </div>
      <Divider />
      <div className="paymentDrawer-body">
        {
          usePaymentSelector && (
            <PaymentTypeSelector
              payNowOrPayAtDoor={payNowOrPayAtDoor}
              setPayNowOrPayAtDoor={setPayNowOrPayAtDoor}
              setPaymentOptionId={setPaymentOptionId}
              {...props}
            />
          )
        }
        {
          payNowOrPayAtDoor === 'payAtDoor'
          && (
            <PayAtDoorOptions
              {...props}
              showDeleteDialog={showDeleteDialog}
              giftCardOption={giftCardOption}
              setGiftCardOption={setGiftCardOption}
              paymentOptionId={paymentOptionId}
              setPaymentOptionId={setPaymentOptionId}
            />
          )
        }
        {
          payNowOrPayAtDoor === 'payNow'
          && (
            <PayNowOptions
              {...props}
              showDeleteDialog={showDeleteDialog}
              giftCardOption={giftCardOption}
              setGiftCardOption={setGiftCardOption}
              paymentOptionId={paymentOptionId}
              setPaymentOptionId={setPaymentOptionId}
            />
          )
        }
        <Divider />
        {
          payNowOrPayAtDoor === 'payNow'
          && (
            <PaymentForm
              {...props}
              giftCardOption={giftCardOption}
              handleProcessPayment={processPayment}
              setPaymentOptionId={setPaymentOptionId}
            />
          )
        }
        <Divider />
        <OrderSummary {...props} />
        <Divider />
        {
          FeatureFlags.CoreView.PaymentDrawer.enableTipping
          && (
            <TipOptions
              {...props}
            />
          )
        }
        <Divider />
        <PickupInformation {...props} />
        <Divider />
        {
          FeatureFlags.CheckoutDrawer.enableInstructionBox
          && (
            <OrderInstructions {...props} />
          )
        }
        <div className="paymentDrawer-sectionContainer">
          <SubTotalSummary translation={translation} paymentDrawer showTotal />
        </div>
      </div>
      <Divider />
      {
        (FeatureFlags.enableGuestCheckout && user.guestToken && showGuestCheckout)
        && (
          <GuestCheckoutRegister
            {...props}
            setGuestPassword={setGuestPassword}
            savePaymentInfo={savePaymentInfo}
            setSavePaymentInfo={setSavePaymentInfo}
            guestSpecialOffers={guestSpecialOffers}
            setGuestSpecialOffers={setGuestSpecialOffers}
            setShowGuestCheckout={setShowGuestCheckout}
            showGuestCheckout={showGuestCheckout}
          />
        )
      }
      <div className="paymentDrawer-footer">
        <PlaceOrderButton
          loading={loading}
          paymentOptionId={paymentOptionId}
          processPayment={(clickEvent) => processPayment(null, clickEvent)}
          translation={translation}
          currentOrder={currentOrder}
        />
      </div>
      {
        deleteDialogOpen
        && (
          <DeleteDialog
            open={deleteDialogOpen}
            translation={translation}
            cardType={paymentOptionToDelete && paymentOptionToDelete.cardType}
            cardName={paymentOptionToDelete && paymentOptionToDelete.cardNumber}
            handleClick={() => deletePaymentOption(paymentOptionToDelete)}
            handleCloseDeleteDialog={() => setDeleteDialogOpen(false)}
          />
        )
      }
    </Drawer>
  );
};

PaymentDrawer.propTypes = {
  open: bool.isRequired,
  isDesktop: bool.isRequired,
  translation: func.isRequired,
  actions: objectOf(func).isRequired,
  company: objectOf(any).isRequired,
  loading: number.isRequired,
  history: objectOf(any).isRequired,
  user: objectOf(any),
  currentOrder: objectOf(any),
  products: arrayOf(objectOf(any)),
  paymentOptions: arrayOf(objectOf(any)),
};

PaymentDrawer.defaultProps = {
  currentOrder: null,
  user: null,
  products: [],
  paymentOptions: [],
};

TipOption.propTypes = {
  tipOption: objectOf(any),
  isSelected: bool,
  setSelectedTip: func.isRequired,
};

TipOption.defaultProps = {
  tipOption: {},
  isSelected: false,
};

CustomTipInput.propTypes = {
  inputRef: func.isRequired,
  onChange: func.isRequired,
};

TipInput.propTypes = {
  translation: func.isRequired,
  actions: objectOf(func).isRequired,
  user: objectOf(any),
  currentOrder: objectOf(any),
};

TipInput.defaultProps = {
  currentOrder: null,
  user: null,
};

TipOptions.propTypes = {
  actions: objectOf(func).isRequired,
  user: objectOf(any),
  currentOrder: objectOf(any),
};

TipOptions.defaultProps = {
  currentOrder: null,
  user: null,
};

const mapStateToProps = state => ({
  open: getIsPaymentDrawerVisible(state),
  currentOrder: getCurrentOrder(state),
  company: getCompany(state),
  currentOrderItems: getCurrentOrderItems(state),
  paymentOptions: getCurrentUserPaymentOptions(state),
  loading: getLoading(state),
  user: getUser(state).user,
});

const EnhancedPaymentDrawer = compose(
  connect(mapStateToProps),
  withWidth(),
)(PaymentDrawer);
export default EnhancedPaymentDrawer;
