import React from 'react';
import { Typography } from '@material-ui/core';
import {
  bool,
  func,
  number,
  string,
} from 'prop-types';
import getThemedClassname from '../../utils/themedClassnames';
import '../../css/shared/SelectableChipWithCount.scss';

const getChipBorderStyle = isSelected => (
  isSelected
    ? 'selectableChipWithCount-container-selected'
    : 'selectableChipWithCount-container-unselected'
);

const getChipLabelStyle = (isSelected, count) => {
  if (count > 0) {
    return isSelected
      ? 'selectableChipWithCount-label-selected'
      : 'selectableChipWithCount-label-unselected';
  }
  return isSelected
    ? 'selectableChipWithCount-label-selected-noCount'
    : 'selectableChipWithCount-label-unselected-noCount';
};

const getCountContainerStyle = isSelected => (
  isSelected
    ? 'selectableChipWithCount-countContainer-selected'
    : 'selectableChipWithCount-countContainer'
);

const SelectableChipWithCount = ({
  isSelected,
  label,
  count,
  onClick,
}) => (
  <div
    className={getThemedClassname(getChipBorderStyle(isSelected))}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onClick();
      }
    }}
  >
    <Typography className={getChipLabelStyle(isSelected, count)}>
      {label}
    </Typography>
    {
      count > 0 && (
        <div className={getCountContainerStyle(isSelected)}>
          <Typography className="selectableChipWithCount-count">
            {count}
          </Typography>
        </div>
      )
    }
  </div>
);

SelectableChipWithCount.propTypes = {
  label: string.isRequired,
  isSelected: bool.isRequired,
  count: number,
  onClick: func,
};

SelectableChipWithCount.defaultProps = {
  count: undefined,
  onClick: () => {},
};

export default SelectableChipWithCount;
