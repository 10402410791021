import React from 'react';
import { CardMedia, Typography } from '@material-ui/core';
import { bool, func } from 'prop-types';

import '../../../css/notificationsPageV2/components/EmptyNotifications.scss';
import { getSharedWebAssetUrl } from '../../../services/functions/Functions';

const EmptyNotifications = ({ isNewNotificationsSelected, translation }) => {
  const imageFileName = isNewNotificationsSelected ? 'no_new_notifications.png' : 'no_read_notifications.png';
  const imageSrc = getSharedWebAssetUrl(imageFileName);
  const text = isNewNotificationsSelected ? 'new' : 'read';

  return (
    <div className="empty-notifications">
      <CardMedia
        className="empty-notifications-cardMedia"
        component="img"
        src={imageSrc}
      />
      <Typography className="empty-notifications-text">{translation(`NotificationsPageV2.emptyNotifications.${text}`)}</Typography>
    </div>
  );
};

EmptyNotifications.propTypes = {
  translation: func.isRequired,
  isNewNotificationsSelected: bool.isRequired,
};

export default EmptyNotifications;
