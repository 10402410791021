import React from 'react';
import { bool } from 'prop-types';
import ContentLoader from 'react-content-loader';
import '../../../css/notificationsPageV2/components/NotificationListSkeletonLoader.scss';
import getThemedResponsiveClassname from '../../../utils/themedResponsiveClassnames';
import { isDarkTheme } from '../../../services/functions/Functions';

const skeletonLoaderList = {
  desktop: {
    height: 67,
    width: 420,
    viewBoxes: [{
      x: '0', y: '0', rx: '9', ry: '20', width: '418', height: '18',
    }, {
      x: '0', y: '28', rx: '6', ry: '10', width: '386', height: '11',
    }, {
      x: '0', y: '45', rx: '6', ry: '10', width: '386', height: '11',
    }],
  },
  mobile: {
    height: 67,
    width: 300,
    viewBoxes: [{
      x: '0', y: '0', rx: '9', ry: '20', width: '300', height: '18',
    }, {
      x: '0', y: '28', rx: '6', ry: '10', width: '277', height: '11',
    }, {
      x: '0', y: '45', rx: '6', ry: '10', width: '277', height: '11',
    }],
  },
  tablet: {
    height: 67,
    width: 870,
    viewBoxes: [{
      x: '0', y: '0', rx: '9', ry: '20', width: '870', height: '18',
    }, {
      x: '0', y: '28', rx: '6', ry: '10', width: '838', height: '11',
    }, {
      x: '0', y: '45', rx: '6', ry: '10', width: '838', height: '11',
    }],
  },
};

const getSkeletonLoaderDimensions = (isMobile, isTablet) => {
  if (isMobile) {
    return skeletonLoaderList.mobile;
  }
  if (isTablet) {
    return skeletonLoaderList.tablet;
  }
  return skeletonLoaderList.desktop;
};

const NotificationListSkeletonLoader = ({ isMobile, isTablet }) => {
  const skeletonLoader = getSkeletonLoaderDimensions(isMobile, isTablet);
  return (
    <div>
      {
        Array.from({ length: 7 }).map(() => (
          <div className={getThemedResponsiveClassname('notificationListSkeletonLoader-itemContainer', isMobile, isTablet)}>
            <ContentLoader
              speed={2}
              viewBox={`0 0 ${skeletonLoader.width} ${skeletonLoader.height}`}
              height={skeletonLoader.height}
              width={skeletonLoader.width}
              backgroundColor={isDarkTheme() ? '#787878' : '#d8d8d8'}
              foregroundColor={isDarkTheme() ? '#a0a0a0' : '#ffffff'}
            >
              {
                skeletonLoader.viewBoxes.map(viewBox => (
                  <rect
                    x={viewBox.x}
                    y={viewBox.y}
                    rx={viewBox.rx}
                    ry={viewBox.ry}
                    width={viewBox.width}
                    height={viewBox.height}
                  />
                ))
              }
            </ContentLoader>
          </div>
        ))
      }
    </div>
  );
};

NotificationListSkeletonLoader.propTypes = {
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
};

export default NotificationListSkeletonLoader;
