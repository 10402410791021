import React from 'react';
import {
  any,
  bool,
  func,
  objectOf,
  oneOf,
} from 'prop-types';
import get from 'lodash/get';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import '../../../css/notificationsPageV2/components/NotificationActionSheet.scss';
import * as Functions from '../../../services/functions/Functions';
import DateHelper from '../../../services/helpers/DateHelper';
import useIsTablet from '../../../shared/hooks/useIsTablet';
import getResponsiveClassname from '../../../utils/responsiveClassnames';
import NotificationActionSheetHeader from './NotificationActionSheetHeader';
import Button from '../../core/components/Button';
import { useHandleClickCTAActionSheet } from '../../../events/Notifications/handleClickCTA';

const NotificationActionSheetComponent = ({
  isOpen,
  setIsOpen,
  width,
  notification,
  translation,
  setError,
}) => {
  const isMobile = Functions.isMobileV2(width);
  const isDark = Functions.isDarkTheme();
  const isTablet = useIsTablet();
  const handleClickCTA = useHandleClickCTAActionSheet();

  return (
    <Drawer
      anchor="bottom"
      ModalProps={{
        BackdropProps: {
          style: {
            backdropFilter: 'blur(10px)',
            webkitBackdropFilter: 'blur(10px)',
          },
        },
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{
        root: 'notificationActionSheet-drawerRoot',
        paperAnchorBottom: getResponsiveClassname(
          'notificationActionSheet-drawerPaperAnchorBottom',
          isMobile,
          isTablet,
        ),
      }}
    >
      <section className={getResponsiveClassname('notificationActionSheet-container', isMobile, isTablet)}>
        <NotificationActionSheetHeader
          isMobile={isMobile}
          isTablet={isTablet}
          title={get(notification, 'subject', '')}
          setIsOpen={setIsOpen}
          imageURL={get(notification, 'imageURL', '')}
        />
        <section className={getResponsiveClassname('notificationActionSheet-containerBelowHeader', isMobile, isTablet)}>
          <section className="notificationActionSheet-spacerBeforeTimestamp" />
          {
            notification && Boolean(notification.sentAt)
            && (
              <p className={`notificationActionSheet-timestamp${isDark ? '-dark' : ''}`}>
                {DateHelper.getFormattedNotificationDate(notification, translation)}
              </p>
            )
          }
          <section className="notificationActionSheet-spacerAfterTimestamp" />
          <p className={getResponsiveClassname('notificationActionSheet-messageText', isMobile, isTablet)}>
            {get(notification, 'messageBody', '')}
          </p>
          <section className="notificationActionSheet-spacerAfterText" />
          {
            notification && Boolean(notification.link)
            && (
              <section className="notificationActionSheet-containerCTA">
                <Button
                  className="notification-card-cta-button"
                  type="primary"
                  text={notification.linkText}
                  onClick={clickEvent => handleClickCTA({
                    clickEvent,
                    url: notification.link,
                    id: notification.id,
                    setError,
                    translation,
                  })}
                  overrideClass
                  keepclickeventonclick
                />
                <section className="notificationActionSheet-spacerAfterCTA" />
              </section>
            )
          }
        </section>
      </section>
    </Drawer>
  );
};

NotificationActionSheetComponent.propTypes = {
  translation: func.isRequired,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  notification: objectOf(any),
  width: oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  setError: func.isRequired,
};

NotificationActionSheetComponent.defaultProps = {
  notification: null,
};

const NotificationActionSheet = (withWidth()(NotificationActionSheetComponent));

export default NotificationActionSheet;
