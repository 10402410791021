import React from 'react';
import PropTypes, { arrayOf, objectOf, any } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconButton, Icon, Badge } from '@material-ui/core';

import * as Routes from '../../../services/routes/Routes.json';
import { getNewNotifications, getNotifications, getUser } from '../../../selectors';
import { checkForNotifications } from '../../../services/helpers/CustomHooks';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import '../../../css/core/components/NotificationIconComponent.scss';
import useCloudEventsBaseParams from '../../../events/hooks/useCloudEventsBaseParams';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';
import BellIconNavigateToNotificationsPageEvent from '../../../events/Notifications/BellIconNavigateToNotificationsPageEvent';

const getUnreadMessageCount = notifications => (notifications
  ? notifications.filter(note => note.isRead === false).length
  : 0);

const NotificationCountIcon = (props) => {
  const {
    notifications,
    newNotifications,
    iconStyleProps,
  } = props;
  const notificationsToUse = FeatureFlags.enableNotificationsV2 ? newNotifications : notifications;
  return (
    <Badge
      overlap="rectangular"
      badgeContent={getUnreadMessageCount(notificationsToUse)}
      color="secondary"
      classes={{ anchorOriginTopRightRectangular: 'notificationIconComponent-anchorOriginTopRightRectangular' }}
    >
      <Icon className={iconStyleProps}>notifications</Icon>
    </Badge>
  );
};

const NotificationBell = (props) => {
  const { user, history } = props;
  checkForNotifications(user.user);
  const cloudEventBaseParams = useCloudEventsBaseParams();

  const handleClick = (clickEvent) => {
    if (clickEvent) {
      const cloudEvent = new BellIconNavigateToNotificationsPageEvent({
        ...cloudEventBaseParams,
        ...getClickEventClientXY(clickEvent),
        route: history && history.location && history.location.pathname,
      });
      CloudEventsApi.sendCloudEvent({
        userToken: user && user.token,
        cloudEvent,
      });
    }
    history.push(Routes.path.notificationsPage);
  };

  return (
    <div className="notificationIconComponent-notificationIconContainer">
      <IconButton
        className="notificationIconComponent-redirectLink"
        onClick={clickEvent => handleClick(clickEvent)}
      >
        <NotificationCountIcon
          {...props}
        />
      </IconButton>
    </div>
  );
};

NotificationBell.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

NotificationBell.defaultProps = {
  user: null,
};

NotificationCountIcon.propTypes = {
  notifications: arrayOf(objectOf(any)),
  newNotifications: arrayOf(objectOf(any)),
  iconStyleProps: PropTypes.string,
};

NotificationCountIcon.defaultProps = {
  notifications: [],
  newNotifications: [],
  iconStyleProps: '',
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  newNotifications: getNewNotifications(state),
  user: getUser(state),
});

const NotificationIconComponent = compose(connect(mapStateToProps))(withRouter(NotificationBell));

export default NotificationIconComponent;
