import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { TranslationClass } from '../i18n/i18n';
import * as Actions from '../actions/Actions';
import * as Routes from '../services/routes/Routes.json';
import CoreRoute from './core/utils/CoreRoute';
import MenuPage from './menuPage/MenuPage';
import LocationsPage from './locationsPage/LocationsPage';
import PaymentMethodPage from './paymentMethodPage/PaymentMethodPage';
import AddNewCardPage from './addNewCardPage/AddNewCardPage';
import SettingsPage from './settingsPage/SettingsPage';
import AccountPage from './accountPage/AccountPage';
import MessageSnackbar from './core/components/MessageSnackbar';
import OrderStatusPage from './orderStatusPage/OrderStatusPage';
import OrdersPage from './ordersPage/OrdersPage';
import SubscriptionsPage from './subscriptionsPage/SubscriptionsPage';
import NotificationsPage from './notificationsPage/NotificationsPage';
import NotificationPage from './notificationsPage/NotificationPage';
import NotificationsPageV2 from './notificationsPageV2/NotificationsPageV2';
import { FeatureFlagClass, FeatureFlags } from '../services/functions/FeatureFlag';
import { generateKey } from '../services/functions/Functions';

import {
  getLoading,
  getDialogLoading,
  getUser,
  getSuccessMessage,
  getErrorMessage,
  getResources,
  getCurrentOrder,
  getLocations,
  getCloudEventSessionId,
} from '../selectors';
import usePageLoadAndExitEvents from '../events/hooks/usePageLoadAndExitEvents';

const getURLSearchParams = () => {
  const params = new URLSearchParams(window.location.search);
  const utmSource = params.get('utm_source');
  const utmMedium = params.get('utm_medium');
  const utmCampaign = params.get('utm_campaign');

  return { utmSource, utmMedium, utmCampaign };
};

const App = ({ t, sessionIdSeam }) => {
  const [user, loading, dialogLoading, errors, success, resources, currentOrder, locations] = useSelector(state => [
    getUser(state),
    getLoading(state),
    getDialogLoading(state),
    getErrorMessage(state),
    getSuccessMessage(state),
    getResources(state),
    getCurrentOrder(state),
    getLocations(state),
  ], shallowEqual);

  const { utmSource, utmMedium, utmCampaign } = getURLSearchParams();

  const sessionId = useSelector(state => getCloudEventSessionId(state));

  const dispatch = useDispatch();

  const actions = bindActionCreators(Actions, dispatch);

  const getUpdatedRemoteContent = () => {
    FeatureFlagClass.getInstance().downloadFile(0);
    TranslationClass.getInstance().downloadFile(0);
  };

  const { upliftHistory } = usePageLoadAndExitEvents();

  useEffect(() => {
    if (utmSource && utmMedium && utmCampaign) {
      actions.setQueryParams({ utmSource, utmMedium, utmCampaign });
    }
  }, [utmSource, utmMedium, utmCampaign]);

  useEffect(() => {
    if (!sessionId) {
      const sessionIdToUse = sessionIdSeam || self.crypto.randomUUID();
      actions.setSessionId(sessionIdToUse);
    }
  }, [sessionId]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (!user.user && localStorage.user) {
      // eslint-disable-next-line no-undef
      actions.setUser(JSON.parse(localStorage.getItem('user')));
    }
    actions.getAllResources(null, ['companies']);
    getUpdatedRemoteContent();
  }, []);

  const coreViewProps = {
    actions,
    loading,
    dialogLoading,
    user: user.user,
    translation: t,
    rememberUser: user.rememberUser,
    currentOrder,
    locations,
  };

  const homePaths = [Routes.path.homePage, Routes.path.menuPage];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BrowserRouter>
        <div className="App">
          <Switch>
            {
              homePaths.map((path, i) =>
              (
                <CoreRoute
                  key={generateKey(i)}
                  exact
                  path={path}
                  coreview={coreViewProps}
                  render={() => <MenuPage actions={actions} loading={loading} user={user.user} translation={t} products={resources.products} categories={resources.categories} upliftHistory={upliftHistory} />}
                />
              ))
            }
            <CoreRoute
              exact
              path={Routes.path.locationsPage}
              coreview={coreViewProps}
              render={() => <LocationsPage actions={actions} loading={loading} translation={t} upliftHistory={upliftHistory} />}
            />
            <CoreRoute
              exact
              path={Routes.path.menuPage}
              coreview={coreViewProps}
              render={() => <MenuPage actions={actions} loading={loading} user={user.user} translation={t} products={resources.products} categories={resources.categories} />}
            />
            <CoreRoute
              exact
              path={Routes.path.addNewCardPage}
              coreview={coreViewProps}
              render={() => <AddNewCardPage user={user.user} loading={loading} translation={t} actions={actions} />}
            />
            <CoreRoute
              exact
              path={Routes.path.paymentMethodPage}
              coreview={coreViewProps}
              render={() => <PaymentMethodPage user={user.user} actions={actions} loading={loading} translation={t} />}
            />
            <CoreRoute
              exact
              path={Routes.path.ordersPage}
              coreview={coreViewProps}
              render={() => <OrdersPage actions={actions} loading={loading} translation={t} upliftHistory={upliftHistory} />}
            />
            <CoreRoute
              exact
              path={Routes.path.settingsPage}
              coreview={coreViewProps}
              render={() => <SettingsPage user={user.user} loading={loading} translation={t} actions={actions} upliftHistory={upliftHistory} />}
            />
            <CoreRoute
              exact
              path={Routes.path.accountPage}
              coreview={coreViewProps}
              render={() => <AccountPage user={user.user} loading={loading} translation={t} actions={actions} />}
            />
            <CoreRoute
              exact
              path={Routes.path.orderStatusPage}
              coreview={coreViewProps}
              render={() => <OrderStatusPage user={user.user} actions={actions} loading={loading} translation={t} upliftHistory={upliftHistory} />}
            />
            <CoreRoute
              exact
              path={Routes.path.notificationsPage}
              coreview={coreViewProps}
              render={() => (
                FeatureFlags.enableNotificationsV2
                  ? <NotificationsPageV2 user={user.user} translation={t} upliftHistory={upliftHistory} />
                  : <NotificationsPage user={user.user} translation={t} upliftHistory={upliftHistory} />
              )}
            />
            <CoreRoute
              exact
              path={`${Routes.path.notificationsPage}/:notificationId`}
              coreview={coreViewProps}
              render={() => <NotificationPage user={user.user} actions={actions} loading={loading} translation={t} upliftHistory={upliftHistory} />}
            />
            <CoreRoute
              exact
              path={Routes.path.subscriptionsPage}
              coreview={coreViewProps}
              render={() => <SubscriptionsPage user={user.user} translation={t} actions={actions} upliftHistory={upliftHistory}/>}
            />
          </Switch>
          <MessageSnackbar
            openMessageSnackbar={!!(errors || success)}
            errorMsg={errors}
            successMsg={success}
            actions={actions}
            translation={t}
          />
        </div>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
};


App.propTypes = {
  t: PropTypes.func.isRequired,
  sessionIdSeam: PropTypes.string,
};

App.defaultProps = {
  sessionIdSeam: null,
};

export default (hot(module)(withNamespaces()(App)));
