import Immutable from 'seamless-immutable';
import { cloneDeep } from 'lodash';
import * as types from '../actions/ActionTypes';
import { compareValues } from '../services/functions/Functions';

const initialState = Immutable({
  notifications: [],
  newNotifications: [],
  readNotifications: [],
});

const getNotificationsByReadStatus = (notifications, isRead) => notifications.sort(compareValues('id', 'desc')).filter(note => note.isRead === isRead);

export default function NotificationReducer(state = initialState, action) {
  let updatedNotifications;
  let updatedNewNotifications;
  let newNotificationListIndex;

  switch (action.type) {
    case types.GET_USER_NOTIFICATIONS_SUCCESS:
      return Immutable.merge(state, {
        notifications: action.notifications.sort(compareValues('id', 'desc')),
        newNotifications: getNotificationsByReadStatus(action.notifications, false),
        readNotifications: getNotificationsByReadStatus(action.notifications, true),
      });
    case types.UPDATE_NOTIFICATION_SUCCESS:
      updatedNotifications = state.notifications.filter(note => note.id !== action.notification.id).concat(action.notification);
      // when on the notification page, update the notification in the newNotifications list, do not push notification to the read list
      newNotificationListIndex = state.newNotifications.findIndex(note => note.id === action.notification.id);
      if (newNotificationListIndex >= 0) {
        updatedNewNotifications = cloneDeep(state.newNotifications);
        updatedNewNotifications[newNotificationListIndex] = action.notification;
      } else {
        updatedNewNotifications = state.newNotifications;
      }
      return ({
        notifications: updatedNotifications,
        newNotifications: updatedNewNotifications,
        readNotifications: state.readNotifications,
      });
    default:
      return state;
  }
}
