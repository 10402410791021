import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import {
  CONTROLS,
  PAGES,
  PLACEMENTS,
  SLOTS,
  TYPES,
} from './constants';

export default class ClickNotificationCTAEvent extends CloudEvent {
  constructor({
    notificationId,
    link,
    userId,
    orderId,
    merchantId,
    idSeam,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.CLICK_NOTIFICATION_CTA,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      notificationId,
      link,
      uxContext: {
        page: PAGES.NOTIFICATIONS,
        slot: SLOTS.L4,
        placement: PLACEMENTS.NOTIFICATION_CARD_LIST,
        control: CONTROLS.NOTIFICATION_CTA_BUTTON,
      },
      position: getPositionObject({ clientX, clientY }),
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
    };
    this.setData(data);
  }
}
